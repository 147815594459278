import { IDropdownOption } from '@fluentui/react/lib/Dropdown';

// NOTE: Use icons from: https://developer.microsoft.com/en-us/fluentui#/styles/web/icons
// A larger set of icons can be found here: https://iconcloud.design/search/filter/Full%20MDL2%20Assets
// Another Fluent icon reference: https://www.flicon.io/
const SystemIcons = {
  // Non-Route based icons.
  ADD: 'Add',
  ANALYTICS: 'AnalyticsView',
  ARROW_DOWN: 'ChevronDown',
  ARROW_FIRST_PAGE: 'DoubleChevronLeft',
  ARROW_NEXT_PAGE: 'ChevronRight',
  ARROW_PREVIOUS_PAGE: 'ChevronLeft',
  ARROW_LAST_PAGE: 'DoubleChevronRight',
  ARROW_UP: 'ChevronUp',
  BACK: 'Back',
  BACK_TO_WINDOW: 'BackToWindow',
  BADGE: 'Ringer',
  CALENDAR: 'Calendar',
  CANCEL: 'Cancel',
  CANCEL_INSTANCE: 'CircleStopSolid',
  CLEAR_FILTER: 'ClearFilter',
  CLOSE: 'ChromeClose',
  CONTACT: 'Contact',
  COPY: 'Copy',
  COPY_URL: 'Link12',
  DELETE: 'Delete',
  DETAILS: 'ShowResults',
  DEVELOPER_TOOLS: 'DeveloperTools',
  DOCUMENTATION: 'Document',
  DOWNLOAD: 'Download',
  DOWNLOAD_DOCUMENT: 'DownloadDocument',
  DRAG: 'DragObject',
  EDIT: 'Edit',
  EDIT_COLUMNS: 'TripleColumnEdit',
  EDIT_LAB: 'Edit',
  ERROR_FULL: 'StatusErrorFull',
  EXPLORE_DATA: 'ExploreData',
  FOLDER: 'FabricFolder',
  FOLDER_OPEN: 'OpenFolderHorizontal',
  GROUP_LIST: 'GroupList',
  INFO: 'Info',
  INSTANCE_MANAGEMENT: 'Source',
  LOGS: 'DataConnectionLibrary',
  MACHINE_BATCH: 'ConnectVirtualMachine',
  MAIL: 'Mail',
  MANAGE_RACK: 'TaskManager',
  MANAGE_USER: 'WorkforceManagement',
  MAXIMIZE: 'AddTo',
  MINIMIZE: 'Blocked12',
  MORE: 'More',
  OPEN_IN_NEW_WINDOW: 'OpenInNewWindow',
  OPEN_IN_NEW_TAB: 'OpenInNewTab',
  PAGE: 'Page',
  PREVIEW: 'Preview',
  REFRESH: 'Refresh',
  REPORT_BUG: 'Bug',
  RESET: 'ClearFilter',
  RESET_DASHBOARD: 'ReturnToSession',
  RESULTS: 'OpenInNewWindow',
  SAVE: 'Save',
  SEARCH: 'Search',
  SESSIONS: 'MapLayers',
  SETTINGS: 'Settings',
  START: 'PlaySolid',
  STEPS_JSON: 'StepShared',
  STOP: 'CircleStop',
  SYNC: 'Sync',
  TAG: 'Tag',
  UNKNOWN: 'Unknown',
  UPDATE_OS_IMAGE: 'ClearFilter',
  UPLOAD: 'Upload',
  VIEW_BUGS: 'BugSolid',
  VIEW_FILE: 'EntryView',
  VIEW_STATUS: 'ReportDocument',
};

const LabIcons = {
  DOWNLOAD_EXE: 'DrillDown',
  DOWNLOAD_MSI: 'DrillDownSolid',
  VIEW_LAB: 'EntryView',
};

const ResultExplorerIcons = {
  ANALYTICS: 'AnalyticsView',
  EXPLORE_DATA: 'ExploreData',
};

export { SystemIcons, LabIcons, ResultExplorerIcons };
