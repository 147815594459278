import React from 'react';
import { observer } from 'mobx-react-lite';
import { PanelType } from '@fluentui/react';
import { t } from 'i18next';

import { Namespaces as NS } from '@/constants/SystemConstants';
import { ColumnEditor } from '@/partials/ColumnEditor/ColumnEditorTemplate';
import SidePanelStyles from '@/partials/SidePanel/SidePanelStyles';
import SidePanel from '@/partials/SidePanel/SidePanelTemplate';

import { ColumnEditorPanelType, ColumnEditorUserSettingsType } from './ColumnEditorPanelTypes';

const ColumnEditorPanelTemplateFC: React.FC<ColumnEditorPanelType> = (props: ColumnEditorPanelType) => {
  const {
    isColumnEditorOpen,
    hideColumnEditor,
    columnsList,
    setColumnsList,
    entireColumns,
    columnEditorKey,
    isColumnArrayObject,
    userSettings: optionalUserSettings,
  } = props;

  // Column Editor Panel configuration.
  const defaultHeaderText = t('choose-columns', { ns: NS.TITLES });
  const defaultUserSettings: ColumnEditorUserSettingsType = {
    type: PanelType.custom,
    headerText: defaultHeaderText,
    isOpen: isColumnEditorOpen,
    onDismiss: hideColumnEditor,
    styles: SidePanelStyles.columnEditorPanel,
  };

  // Allow the user to pass in their own optional settings, if they exist.
  const userSettings = { ...defaultUserSettings, ...optionalUserSettings };

  const content = (
    <ColumnEditor
      columnsList={columnsList}
      setColumnsList={(value: any) => setColumnsList(value)}
      hideColumnEditor={hideColumnEditor}
      entireColumns={entireColumns}
      columnEditorKey={columnEditorKey}
    ></ColumnEditor>
  );

  return <SidePanel userSettings={userSettings} content={content}></SidePanel>;
};

const ColumnEditorPanelTemplate = observer(ColumnEditorPanelTemplateFC);

export default ColumnEditorPanelTemplate;
