import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { DefaultButton, MessageBarType as FluentMessageBarType, PrimaryButton } from '@fluentui/react';
import { PanelType } from '@fluentui/react/lib/Panel';
import { Badge } from '@fluentui/react-components';
import { t } from 'i18next';

import SessionPanelStyles from '@/components/SessionPanel/SessionPanelStyles';
import { SessionType } from '@/components/Sessions/SessionsTypes';
import { TimeInterval } from '@/constants/DateFormatConstants';
import { TabMemoryKeys } from '@/constants/ExperimentConstants';
import { SystemIcons } from '@/constants/IconConstants';
import { Namespaces as NS, SuccessGroupIds, Units } from '@/constants/SystemConstants';
import { onDismissType } from '@/partials/MessageBar/MessageBarTypes';
import { TITLE_DIVIDER } from '@/partials/PageHeader/PageHeaderConstants';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { SystemMessageType } from '@/types/SystemMessageTypes';

import SessionPanelTemplate from './SessionPanelTemplate';
import { SessionPanelVMType } from './SessionPanelTypes';

import styles from './SessionPanel.module.css';

interface SessionPanelVCProps {
  viewModel: SessionPanelVMType;
}

const SessionPanelViewControllerFC: React.FC<SessionPanelVCProps> = ({ viewModel }) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, sessionsStore, sessionDetailsStore } = rootStore;
  const { isCollapsed, setTabMemory, isAdvancedMode } = appSettingsStore;
  const { selectedSession, isSessionSelected, selectedSessionIsCancellable } = sessionsStore;
  const {
    buildSessionRoutePath,
    closeSessionPanel,
    sessionStep,
    isCancelButtonDisabled,
    isLogWindowItemSelected,
    isSessionPanelOpen,
    isSessionStepWindowSelected,
  } = sessionDetailsStore;

  const { loadMessage } = viewModel;
  const [content, setContent] = React.useState<React.ReactElement>(<></>);
  const [localMessage, setLocalMessage] = React.useState<string>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [pathname, setPathname] = React.useState<string>('');
  const status = selectedSession?.status;
  const sessionId = isSessionSelected ? selectedSession.id : '';
  const labId: string = isSessionSelected ? selectedSession.location?.labId?.toString() : '';

  const title =
    `${t('experiment', { ns: NS.DEFAULT })}` +
    (selectedSession ? ` ${TITLE_DIVIDER} ${selectedSession.name} ${TITLE_DIVIDER} ${selectedSession.id}` : '');

  const cancelOnClick = () => {
    // Cancel session.
    // TODO: Implement cancel session via API (28393226).
    const { addSessionPanelMessage, enableCancelButton, disableCancelButton } = sessionDetailsStore;
    const cancelConfirmation = t('cancel-session-confirmation', { ns: NS.EXPERIMENTS });

    const cancelMessage: SystemMessageType = {
      message: t('cancel-not-implemented', { ns: NS.EXPERIMENTS }),
      namespace: NS.EXPERIMENTS,
      type: FluentMessageBarType.info,
      groupId: SuccessGroupIds.SESSION_VIEW,
    };

    const simulateCancelOperation = () => {
      enableCancelButton();
    };

    if (confirm(cancelConfirmation)) {
      disableCancelButton();
      setTimeout(simulateCancelOperation, TimeInterval.THREE_SECONDS);
      addSessionPanelMessage(cancelMessage);
    }
  };

  // Build the footer content.
  const onRenderFooterContent: () => React.ReactElement = React.useCallback(() => {
    return (
      <div className={styles['footer-container']}>
        <PrimaryButton
          type="button"
          onClick={closeSessionPanel}
          text={t('close', { ns: NS.COMMON })}
          title={t('close-panel', { ns: NS.COMMON })}
          ariaLabel={t('close-panel', { ns: NS.COMMON })}
          styles={SessionPanelStyles.noWrapDefaultButton}
          className={styles['button']}
        ></PrimaryButton>

        <Link
          to={{
            pathname: pathname,
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <DefaultButton
            iconProps={{ iconName: SystemIcons.OPEN_IN_NEW_WINDOW }}
            type="button"
            text={t('open-new-window', { ns: NS.EXPERIMENTS })}
            title={t('open-new-window', { ns: NS.EXPERIMENTS })}
            ariaLabel={t('open-new-window', { ns: NS.EXPERIMENTS })}
            className={styles['button']}
            styles={SessionPanelStyles.noWrapDefaultButton}
            disabled={!isSessionSelected || !isSessionPanelOpen}
          ></DefaultButton>
        </Link>

        <DefaultButton
          iconProps={{ iconName: SystemIcons.CANCEL }}
          type="button"
          text={t('cancel-session', { ns: NS.EXPERIMENTS })}
          title={t('cancel-session', { ns: NS.EXPERIMENTS })}
          ariaLabel={t('cancel-session', { ns: NS.EXPERIMENTS })}
          className={styles['button']}
          onClick={cancelOnClick}
          styles={SessionPanelStyles.noWrapDefaultButton}
          disabled={!selectedSessionIsCancellable || isCancelButtonDisabled}
        ></DefaultButton>

        {isAdvancedMode && (
          <>
            <Badge>Steps: {isSessionStepWindowSelected.toString()}</Badge> &nbsp;
            <Badge>Logs: {isLogWindowItemSelected.toString()}</Badge> &nbsp;
          </>
        )}
        {isAdvancedMode && sessionStep && (
          <>
            <Badge>StepId: {sessionStep?.id}</Badge> &nbsp;
            <Badge>InstanceId: {sessionStep?.experimentId}</Badge> &nbsp;
          </>
        )}
      </div>
    );
  }, [pathname, selectedSession, sessionStep, isSessionStepWindowSelected, isLogWindowItemSelected]);

  const userSettings = {
    headerText: title,
    type: PanelType.custom,
    customWidth: Units.Eighty_Percent,
    isOpen: isSessionPanelOpen,
    onDismiss: closeSessionPanel,
    onRenderFooterContent: onRenderFooterContent,
    styles: isCollapsed ? SessionPanelStyles.wideSessionPanel : SessionPanelStyles.regularSessionPanel,
  };

  const onTabClick = (item: React.ReactElement) => {
    const key = item.props.itemKey;

    setTabMemory(TabMemoryKeys.SESSION_DETAILS_PANEL, key);
  };

  const onDismissMessage: onDismissType = () => {
    setLocalMessage(null);
  };

  const resetPanel = () => {
    const message: string = loadMessage || null;
    const pathname: string = buildSessionRoutePath;

    setPathname(pathname);
    setLocalMessage(message); // Clear any messages in this Panel.
  };

  React.useEffect(() => {
    selectedSession && resetPanel();
  }, [selectedSession, isSessionSelected, status, loadMessage]);

  return (
    <SessionPanelTemplate
      content={content}
      setContent={setContent}
      localMessage={localMessage}
      setLocalMessage={setLocalMessage}
      onDismissMessage={onDismissMessage}
      isLoading={isLoading}
      onTabClick={onTabClick}
      userSettings={userSettings}
      title={title}
      labId={labId}
      sessionId={sessionId}
    ></SessionPanelTemplate>
  );
};

const SessionPanelViewController = observer(SessionPanelViewControllerFC);

export default SessionPanelViewController;
