import React from 'react';
import { t } from 'i18next';
import { container, injectable } from 'tsyringe';

import LabsControlStore from '@/components/_labs/Labs/LabsControl/LabsControlStore';
import CosmosStore from '@/components/Cosmos/CosmosStore';
import CreateLabStore from '@/components/CreateLab/CreateLabStore';
import DocumentationStore from '@/components/Documentation/DocumentationStore';
import ExperimentDetailsStore from '@/components/ExperimentDetails/ExperimentDetailsStore';
import ExperimentEditorStore from '@/components/ExperimentEditor/ExperimentEditorStore';
import ExperimentsStore from '@/components/Experiments/ExperimentsStore';
import LabListStore from '@/components/LabList/LabListStore';
import LabPanelStore from '@/components/LabPanel/LabPanelStore';
import LogsViewPanelStore from '@/components/LogsViewPanel/LogsViewPanelStore';
import MachineSelectStore from '@/components/MachineSelect/MachineSelectStore';
import LabDetailsStore from '@/components/ManageLab/LabDetails/LabDetailsStore';
import LabGeneralStore from '@/components/ManageLab/LabGeneral/LabGeneralStore';
import PreviewExperimentStore from '@/components/PreviewExperiment/PreviewExperimentStore';
import ErrorsGraphStore from '@/components/Reliability/ErrorsGraph/ErrorsGraphStore';
import ReliabilityStore from '@/components/Reliability/ReliabilityStore';
import ResultExplorerStore from '@/components/ResultExplorer/ResultExplorerStore';
import SessionDetailsStore from '@/components/SessionDetails/SessionDetailsStore';
import SessionsStore from '@/components/Sessions/SessionsStore';
import UseGraphStore from '@/components/UseGraph/UseGraphStore';
import UserInfoStore from '@/components/UserInfo/UserInfoStore';
import EditColumnsStore from '@/partials/ColumnEditor/ColumnEditorStore';
import PaginationStore from '@/partials/Pagination/PaginationStore';
import RawLogsTreeViewStore from '@/partials/RawLogsTreeView/RawLogsTreeViewStore';
import TableViewStore from '@/partials/TableView/TableViewStore';
import LocalStorageService from '@/services/LocalStorageService';
import AirSettingsStore from '@/stores/AirSettingsStore';
import UserSettingsStore from '@/stores/UserSettingsStore';

import AppSettingsStore from './AppSettingsStore';
import SystemMessageStore from './SystemMessageStore';

@injectable()
export class RootStore {
  // Initial store declarations.
  localStorage: LocalStorageService;
  appSettingsStore: AppSettingsStore;
  systemMessageStore: SystemMessageStore;
  airSettingsStore: AirSettingsStore;
  userInfoStore: UserInfoStore;

  // Other store declarations.
  createLabStore: CreateLabStore;
  cosmosStore: CosmosStore;
  documentationStore: DocumentationStore;
  editColumnsStore: EditColumnsStore;
  errorGraphStore: ErrorsGraphStore;
  experimentDetailsStore: ExperimentDetailsStore;
  experimentEditorStore: ExperimentEditorStore;
  experimentsStore: ExperimentsStore;
  labDetailsStore: LabDetailsStore;
  labGeneralStore: LabGeneralStore;
  labPanelStore: LabPanelStore;
  labListStore: LabListStore;
  labsControlStore: LabsControlStore;
  logsViewPanelStore: LogsViewPanelStore;
  machineSelectStore: MachineSelectStore;
  previewExperimentStore: PreviewExperimentStore;
  paginationStore: PaginationStore;
  reliabilityStore: ReliabilityStore;
  resultExplorerStore: ResultExplorerStore;
  rawLogsTreeViewStore: RawLogsTreeViewStore;
  sessionsStore: SessionsStore;
  sessionDetailsStore: SessionDetailsStore;
  useGraphStore: UseGraphStore;
  userSettingsStore: UserSettingsStore;
  tableViewStore: TableViewStore;

  constructor() {
    // Initial constructor declarations.
    this.localStorage = container.resolve(LocalStorageService);
    this.appSettingsStore = new AppSettingsStore(this);
    this.systemMessageStore = new SystemMessageStore(this);
    this.airSettingsStore = new AirSettingsStore(this);
    this.userInfoStore = new UserInfoStore(this);

    // Other constructor declarations.
    this.createLabStore = new CreateLabStore(this);
    this.cosmosStore = new CosmosStore(this);
    this.documentationStore = new DocumentationStore(this);
    this.editColumnsStore = new EditColumnsStore(this);
    this.errorGraphStore = new ErrorsGraphStore(this);
    this.experimentDetailsStore = new ExperimentDetailsStore(this);
    this.experimentEditorStore = new ExperimentEditorStore(this);
    this.experimentsStore = new ExperimentsStore(this);
    this.labDetailsStore = new LabDetailsStore(this);
    this.labGeneralStore = new LabGeneralStore(this);
    this.labPanelStore = new LabPanelStore(this);
    this.labListStore = new LabListStore(this);
    this.labsControlStore = new LabsControlStore(this);
    this.logsViewPanelStore = new LogsViewPanelStore(this);
    this.machineSelectStore = new MachineSelectStore(this);
    this.previewExperimentStore = new PreviewExperimentStore(this);
    this.paginationStore = new PaginationStore(this);
    this.reliabilityStore = new ReliabilityStore(this);
    this.resultExplorerStore = new ResultExplorerStore(this);
    this.rawLogsTreeViewStore = new RawLogsTreeViewStore(this);
    this.sessionsStore = new SessionsStore(this);
    this.sessionDetailsStore = new SessionDetailsStore(this);
    this.useGraphStore = new UseGraphStore(this);
    this.userSettingsStore = new UserSettingsStore(this);
    this.tableViewStore = new TableViewStore(this);
  }
}

export const RootStoreContext = React.createContext(new RootStore());
