import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { t } from 'i18next';

import { Namespaces as NS } from '@/constants/SystemConstants';
import { PageCommandBar } from '@/constants/TranslationConstants';

import { extractString } from './Helpers';

const createGroupByNoneItem = (onClick): ICommandBarItemProps => {
  const groupByNoneKey = PageCommandBar.GROUPBY_NONE;
  const groupByNoneText = t(groupByNoneKey, { ns: NS.COMMON });

  return {
    key: groupByNoneKey,
    text: groupByNoneText,
    onClick: () => onClick(groupByNoneText),
  };
};

const createGroup = (name: string, startIndex: number, count: number) => {
  const group = {
    name: name,
    key: name,
    startIndex: startIndex,
    count: count,
    level: 0,
  };

  return group;
};

const generateGroups = (tableData: any[], parentGroupData: any[], fieldName: string) => {
  if (tableData.length <= 0 || parentGroupData.length <= 0) {
    return [];
  }

  const groups = [];
  let currentValue = '';

  // Create a hash map to count the occurrences of each group
  const groupCounts = tableData.reduce((counts, item) => {
    const value = extractString(item[fieldName]);
    counts[value] = (counts[value] || 0) + 1;
    return counts;
  }, {});

  for (let i = 0; i < parentGroupData.length; i++) {
    currentValue = parentGroupData[i as number][fieldName].trim();

    const currentIdx = tableData.findIndex((item) => extractString(item[fieldName]) === currentValue);
    const currentGroupCount = groupCounts[currentValue];

    groups.push(createGroup(currentValue, currentIdx, currentGroupCount));
  }

  return groups;
};

// To get the unique group by data
const getGroupParents = (tableData: any[], fieldName: string) => {
  const uniqueValues = Array.from(new Set(tableData.map((obj) => extractString(obj[fieldName]).trim())));
  const groupParents = uniqueValues.sort().map((value) => ({ [fieldName]: value.trim() }));

  return groupParents;
};

const getSortedData = (tableData: any[], fieldName?: string) => {
  const sortedData = [...tableData].sort((a, b) => {
    const aValue = extractString(a[fieldName]);
    const bValue = extractString(b[fieldName]);

    if (aValue === null || bValue === null) {
      return aValue === null ? 1 : -1;
    }

    return aValue.localeCompare(bValue);
  });

  return sortedData;
};

const setTableDataGroupBy = (tableData: any[], groupByColumnKey: string, tableColumns: any[]) => {
  const matchingColumn = tableColumns.find((column: any) => column.key === groupByColumnKey);

  if (matchingColumn) {
    const fieldName = matchingColumn.fieldName;
    const sortedTableData = getSortedData(tableData, fieldName);

    const groupParents = getGroupParents(sortedTableData, fieldName);
    const groups = generateGroups(sortedTableData, groupParents, fieldName);

    return { returnData: sortedTableData, groups };
  } else {
    // When there is no matching column or group by none is selected
    const groups = null;
    return { returnData: tableData, groups };
  }
};

export { createGroupByNoneItem, setTableDataGroupBy };
