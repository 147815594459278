import { action, makeObservable, observable } from 'mobx';

import { LabType } from '@/components/Experiments/ExperimentsTypes';
import LocalStorageService from '@/services/LocalStorageService';
import { RootStore } from '@/stores/RootStore';

class LabDetailsStore {
  protected localStorage: LocalStorageService;
  protected rootStore: RootStore;

  public isLabBusy = true;
  public labList: LabType[];
  public selectedLab: LabType = null;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      // Observables not persisted.
      isLabBusy: observable,
      labList: observable,
      selectedLab: observable,

      // Actions modify the state.
      setBusy: action,
      setLabs: action,
      setSelectedLab: action,
    });

    this.localStorage = rootStore.localStorage;
    this.rootStore = rootStore;
  }

  public setBusy = (busy: boolean) => {
    this.isLabBusy = busy;
  };

  public setLabs = (labs: LabType[]) => {
    this.labList = labs;
  };

  public setSelectedLab = (lab: LabType) => {
    this.selectedLab = lab;
  };
}

export default LabDetailsStore;
