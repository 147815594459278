import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import LabDetailsViewController from '@/components/ManageLab/LabDetails/LabDetailsViewController';
import LabDetailsViewModel from '@/components/ManageLab/LabDetails/LabDetailsViewModel';
import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { SystemMessageType } from '@/types/SystemMessageTypes';

const LabDetailsHomeFC: React.FC = (): React.ReactElement => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { systemMessageStore } = rootStore;
  const { globalMessages } = systemMessageStore;

  const labsViewModel = useMemo(() => new LabDetailsViewModel(rootStore), [rootStore]);

  const systemMessage: SystemMessageType[] = globalMessages?.filter((message: SystemMessageType) => !message.showInPopup);

  return (
    <div className="fullscreen">
      <MessageBarTemplate>{systemMessage}</MessageBarTemplate>
      <main className="fullscreen container ganymede-wrapper">
        <LabDetailsViewController viewModel={labsViewModel} />
      </main>
    </div>
  );
};

const LabDetailsHome = observer(LabDetailsHomeFC);

export default LabDetailsHome;
