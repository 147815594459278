import React from 'react';
import { observer } from 'mobx-react-lite';

import FooterTemplate from './FooterTemplate';
import { FooterType } from './FooterTypes';

const FooterViewControllerFC: React.FC<{ viewModel: FooterType }> = (props: { viewModel: FooterType }) => {
  const {
    isDebugMode,
    isEditMode,
    isAdvancedMode,
    isDeveloperMode,
    isOfflineMode,
    isPageLoading,
    isDarkMode,
    isSettingsOpen,
    debugModeMessage,
    editModeMessage,
    advancedModeMessage,
    developerModeMessage,
  } = props.viewModel;

  return (
    <FooterTemplate
      isDebugMode={isDebugMode}
      isEditMode={isEditMode}
      isAdvancedMode={isAdvancedMode}
      isDeveloperMode={isDeveloperMode}
      isPageLoading={isPageLoading}
      isOfflineMode={isOfflineMode}
      isDarkMode={isDarkMode}
      isSettingsOpen={isSettingsOpen}
      debugModeMessage={debugModeMessage}
      editModeMessage={editModeMessage}
      advancedModeMessage={advancedModeMessage}
      developerModeMessage={developerModeMessage}
    ></FooterTemplate>
  );
};

const FooterViewController = observer(FooterViewControllerFC);

export default FooterViewController;
