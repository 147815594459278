import React from 'react';
import { observer } from 'mobx-react-lite';

import SessionDetails from '@/components/SessionDetails/SessionDetailsWrapper';
import { SessionPanelVCType } from '@/components/SessionPanel/SessionPanelTypes';
import { TabMemoryKeys } from '@/constants/ExperimentConstants';
import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import SidePanel from '@/partials/SidePanel/SidePanelTemplate';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

const SessionPanelTemplateFC: React.FC<SessionPanelVCType> = (props: SessionPanelVCType) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, sessionsStore, sessionDetailsStore } = rootStore;
  const { tabMemory } = appSettingsStore;
  const { sessionPanelMessages, hasPanelMessages, panelMessageCount } = sessionDetailsStore;

  const activeTab: string = tabMemory[TabMemoryKeys.SESSION_DETAILS_PANEL];
  const { isSessionSelected, selectedSession } = sessionsStore;
  const { content, setContent, localMessage, onDismissMessage, userSettings, title, sessionId } = props;

  React.useEffect(() => {
    const panel = (
      <>
        {hasPanelMessages && <MessageBarTemplate>{sessionPanelMessages}</MessageBarTemplate>}
        <SessionDetails title={title} sessionId={sessionId}></SessionDetails>
      </>
    );

    setContent(panel);
  }, [selectedSession, tabMemory, activeTab, localMessage, hasPanelMessages, panelMessageCount, isSessionSelected]);

  return <SidePanel userSettings={userSettings} content={content}></SidePanel>;
};

const SessionPanelTemplate = observer(SessionPanelTemplateFC);

export default SessionPanelTemplate;
