/*----------------------------------------------------------------------------------------------------------------------------
# Footer.tsx: 
# This Component is our footer Navigation Component. 
# All Footer related GUI and CSS styling should originate here.
#----------------------------------------------------------------------------------------------------------------------------*/

import React from 'react';
import { observer } from 'mobx-react-lite';

import Badge from '@/partials/Badge/BadgeTemplate';
import AppSettingsService from '@/services/AppSettingsService';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

import { FooterType } from './FooterTypes';

import styles from './Footer.module.css'; // Apply any Navigation level CSS Module styling here.

const FooterFC: React.FC<FooterType> = (): React.ReactElement => {
  const appSettings = new AppSettingsService(); // TODO: JOHN CHECK IF THIS IS CORRECT.
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, sessionsStore, sessionDetailsStore, userSettingsStore } = rootStore;
  const { isDebugMode, isEditMode, isAdvancedMode, isDeveloperMode, isOfflineMode, isDarkMode, isSettingsOpen, isPageLoading } =
    appSettingsStore;
  const { selectedSession, selectedSessions } = sessionsStore;
  const { isSessionPanelOpen, isSessionColumnEditorOpen, isSessionStepColumnEditorOpen } = sessionDetailsStore;
  const { timeZone } = userSettingsStore;

  return (
    <div className={styles['footer']}>
      <div className={styles['footer-left']}>
        <Badge>Version: {appSettings.version}</Badge>
        {isAdvancedMode && (
          <>
            <Badge>
              SSN: {selectedSessions?.length || 0}
              {selectedSession?.id ? ` (${selectedSession.id})` : ''}
            </Badge>
            <Badge>SPO: {isSessionPanelOpen.toString()}</Badge>
            <Badge>SCEO: {isSessionColumnEditorOpen.toString()}</Badge>
            <Badge>SSCEO: {isSessionStepColumnEditorOpen.toString()}</Badge>
          </>
        )}
      </div>
      <div className={styles['footer-middle']}>{isPageLoading && <Badge>...</Badge>}</div>
      <div className={styles['footer-right']}>
        {isDebugMode && <Badge>DBG</Badge>}
        {isEditMode && <Badge>EDT</Badge>}
        {isAdvancedMode && <Badge>AVD</Badge>}
        {isDeveloperMode && <Badge>DEV</Badge>}
        {isOfflineMode && <Badge>OFF</Badge>}
        {isDarkMode && <Badge>DRK</Badge>}
        {isSettingsOpen && <Badge>SET</Badge>}
        <Badge>TZ: {timeZone}</Badge>
      </div>
    </div>
  );
};

const Footer = observer(FooterFC);

export default Footer;
