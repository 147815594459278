import React from 'react';
import { MessageBarType as FluentMessageBarType } from '@fluentui/react';
import { CancelToken } from 'axios';
import { t } from 'i18next';
import moment from 'moment-timezone';
import format from 'string-template';

import { LabType } from '@/components/Experiments/ExperimentsTypes';
import SessionDetailsStore from '@/components/SessionDetails/SessionDetailsStore';
import { DateFormats } from '@/constants/DateFormatConstants';
import { Statuses } from '@/constants/ExperimentConstants';
import { Delimiters, FilterOptions, KeyTextPair, Namespaces as NS } from '@/constants/SystemConstants';
import { MessageBarMode } from '@/partials/MessageBar/MessageBarTypes';
import PaginationStore from '@/partials/Pagination/PaginationStore';
import { PaginationType } from '@/partials/Pagination/PaginationTypes';
import { ganymedeLabRequestService } from '@/services/request-services/LabRequestService';
import { GanymedeSessionRequestService } from '@/services/request-services/SessionRequestService';
import AppSettingsStore from '@/stores/AppSettingsStore';
import { RootStore } from '@/stores/RootStore';
import SystemMessageStore from '@/stores/SystemMessageStore';
import UserSettingsStore from '@/stores/UserSettingsStore';
import { SystemMessageType } from '@/types/SystemMessageTypes';
import { formatDate, sortByCreatedDate } from '@/utils/Dates';
import { setTableDataGroupBy } from '@/utils/GroupBy';
import { statusCell } from '@/utils/Helpers';

import SessionsStore from './SessionsStore';
import { LocationType, SessionGUIType, SessionStatusType, SessionStepType, SessionType } from './SessionsTypes';

class SessionsViewModel {
  public addGlobalMessage;
  public isDebugMode: boolean;

  protected rootStore: RootStore;
  protected appSettingsStore: AppSettingsStore;
  protected paginationStore: PaginationStore;
  protected systemMessageStore: SystemMessageStore;
  protected userSettingsStore: UserSettingsStore;
  protected sessionsStore: SessionsStore;

  protected _requestCounter: number;
  protected _searchValue: string;
  protected _locationValue: string;
  protected _statusValues: string[];
  protected _ipAddresses: string[];
  protected _ipAddressPairs: KeyTextPair[];
  protected _startDate: Date;
  protected _endDate: Date;
  protected _lastRunTime: string;

  constructor(rootStore: RootStore) {
    const { appSettingsStore, paginationStore, sessionsStore, systemMessageStore, userSettingsStore } = rootStore;
    const { isDebugMode } = appSettingsStore;
    const { searchValue, locationValue, statusValues, ipAddresses, ipAddressPairs, startDate, endDate, lastRunTimeRange } =
      sessionsStore;
    const { addGlobalMessage } = systemMessageStore;

    this.rootStore = rootStore;
    this.appSettingsStore = appSettingsStore;
    this.paginationStore = paginationStore;
    this.sessionsStore = sessionsStore;
    this.systemMessageStore = systemMessageStore;
    this.userSettingsStore = userSettingsStore;

    this.addGlobalMessage = addGlobalMessage;
    this.isDebugMode = isDebugMode;

    this._requestCounter = 0;
    this._searchValue = searchValue;
    this._locationValue = locationValue;
    this._statusValues = statusValues;
    this._ipAddresses = ipAddresses;
    this._ipAddressPairs = ipAddressPairs;
    this._startDate = startDate;
    this._endDate = endDate;
    this._lastRunTime = lastRunTimeRange;
  }

  static buildSession = (rootStore: RootStore, data: SessionType): SessionType & SessionGUIType => {
    const { userSettingsStore } = rootStore;
    const { timeZone } = userSettingsStore;

    if (data) {
      // Raw data from the API.
      const id: string = data.id;
      const name: string = data.name;
      const description: string = data.description;
      const status: SessionStatusType = data.status;
      const owner: string = data.owner;
      const instances: string[] = data.instances;
      const schedule: string = data.schedule;
      const location: LocationType = data.location;
      const created: string = data.created;
      const started: string = data.started;
      const lastModified: string = data.lastModified;
      const json = data.json;
      const rawJson = data.rawJson;

      // Formatted fields for display purposes.
      const displayName: string = name.toString();
      const displayStatus: string | React.ReactElement = statusCell(status.final);
      const displayInstances: string = instances?.join(', ') || '';
      const displayLocation: string = this.getDisplayLocation(location);
      const createdTime: string = created ? formatDate(created, timeZone) : '';
      const startedTime: string = started ? formatDate(started, timeZone) : '';
      const lastModifiedTime: string = lastModified ? formatDate(lastModified, timeZone) : '';

      const session: SessionType & SessionGUIType = {
        id,
        name,
        displayName,
        description,
        status,
        displayStatus,
        owner,
        instances,
        displayInstances,
        schedule,
        location,
        displayLocation,
        created,
        createdTime,
        started,
        startedTime,
        lastModified,
        lastModifiedTime,
        json,
        rawJson,
      };

      return session;
    }

    return null as SessionType & SessionGUIType;
  };

  static buildSessionStep = (rootStore: RootStore, data: SessionStepType): SessionStepType => {
    const { userSettingsStore } = rootStore;
    const { timeZone } = userSettingsStore;

    if (data) {
      const id: string = data.id;
      const name: string = data.name;
      const displayName: string | React.ReactElement = data.name;
      const experimentId: string = data.experimentId;
      const status: string = data.status;
      const experimentGroup: string = data.experimentGroup;
      const stepType: string = data.stepType;
      const sequence: number = data.sequence;
      const startTime: string = formatDate(data.startTime, timeZone);
      const endTime: string = formatDate(data.endTime, timeZone);
      const location: string = data.location || Delimiters.DASH;
      const agentId: string = data.agentId || Delimiters.DASH;
      const stepJSON = data.stepJSON || '{}'; // This will be formatted when used later.

      const step: SessionStepType = {
        id,
        name,
        displayName,
        experimentId,
        status,
        experimentGroup,
        stepType,
        sequence,
        startTime,
        endTime,
        location,
        agentId,
        stepJSON,
      };

      return step;
    }

    return null as SessionStepType;
  };

  static getDisplayLocation = (value: LocationType): string => {
    const { labId, machineIds, labName, machineNames, location } = value;

    if (location) {
      return location;
    }

    if (labName) {
      return labName;
    }

    if (labId) {
      return labId.toString();
    }

    if (machineNames) {
      return machineNames.join(',');
    }

    if (machineIds) {
      return machineIds.join(',');
    }

    return Delimiters.DASH;
  };

  public bumpRequestCounter = (requestId: React.MutableRefObject<number>): number => {
    ++this._requestCounter;

    requestId.current = this._requestCounter;

    return this._requestCounter;
  };

  public isRecentSession = (date: string, days: number): boolean => {
    const currentTime = moment.tz(this.userSettingsStore.timeZone);
    const compareDate = moment.tz(date, DateFormats.STANDARD_DATE_TIME, this.userSettingsStore.timeZone);

    const diff = moment.duration(currentTime.diff(compareDate));

    return diff.asDays() < days;
  };

  public calculateStatus = (data: any): string => {
    let finalStatus = t('not-available', { ns: NS.COMMON });

    if (data.length > 0) {
      if (data.every((i) => i.status?.toLowerCase() === Statuses.PENDING)) {
        finalStatus = t('pending', { ns: NS.COMMON });
      } else if (
        data.some((i) => i.status?.toLowerCase() === Statuses.INPROGRESS) ||
        data.some((i) => i.status?.toLowerCase() === Statuses.PENDING)
      ) {
        finalStatus = t('in-progress', { ns: NS.COMMON });
      } else if (data.some((i) => i.status?.toLowerCase() === Statuses.FAILED)) {
        finalStatus = t('failed', { ns: NS.COMMON });
      } else if (data.some((i) => i.status?.toLowerCase() === Statuses.CANCELLED)) {
        finalStatus = t('cancelled', { ns: NS.COMMON });
      } else if (data.every((i) => i.status?.toLowerCase() === Statuses.SUCCEEDED)) {
        finalStatus = t('succeeded', { ns: NS.COMMON });
      }
    }

    return finalStatus;
  };

  // Let us know when any filters have been applied.
  public hasFiltersApplied = (): boolean => {
    const searchFilter = !!this._searchValue.trim();
    const locationFilter = !!this._locationValue;
    const statusFilter = this._statusValues.length > 0;
    const ipAddressFilter = this._ipAddresses.length > 0;
    const dateFilter = this._lastRunTime !== FilterOptions.ALL;

    return searchFilter || locationFilter || statusFilter || ipAddressFilter || dateFilter;
  };

  public requestCounter = (): number => {
    return this._requestCounter;
  };

  public getLabWithLatestHeartbeat = (labs: LabType[]): LabType => {
    const latestLab: LabType = labs.reduce((latestLab: LabType, currentLab: LabType) => {
      const latestHeartbeat = latestLab.LastHeartBeat ? new Date(latestLab.LastHeartBeat) : new Date(0);
      const currentHeartbeat = currentLab.LastHeartBeat ? new Date(currentLab.LastHeartBeat) : new Date(0);

      return currentHeartbeat > latestHeartbeat ? currentLab : latestLab;
    });

    return latestLab;
  };

  public loadIpAddresses = async (cancelToken: CancelToken) => {
    const { isDebugMode } = this.appSettingsStore;
    const { locationValue, setIsIpAddressLoading, setIpAddressPairs } = this.sessionsStore;

    const labId = Number(locationValue);

    setIsIpAddressLoading(true);

    isDebugMode && console.log(`[SessionsViewModel:loadIpAddresses] Loading IP Addresses for Lab=${locationValue}.`);

    try {
      if (!isNaN(labId)) {
        const ipAddresses: string[] = await GanymedeSessionRequestService.getIPAddress(labId, cancelToken);
        const ipAddressKeyTextPair: KeyTextPair[] = ipAddresses.map((ip: string) => ({ key: ip, text: ip }));

        setIpAddressPairs(ipAddressKeyTextPair);
      } else {
        setIpAddressPairs([]);
      }
    } catch (error) {
      console.error('[SessionsViewModel:loadIpAddresses] Error fetching IP Address:', error);

      // Append an error to the message bar.
      const message: SystemMessageType = {
        message: t('experiments-api-error-ipaddress'),
        id: 'experiments-api-error-ipaddress',
        type: FluentMessageBarType.error,
        mode: MessageBarMode.normal,
        namespace: NS.ERRORS,
        groupId: 'experiments-api-group',
      };

      setIpAddressPairs([]);
    } finally {
      setIsIpAddressLoading(false);
    }
  };

  public loadLocations = async (labId: string, isPartnerMode: boolean) => {
    const { isDebugMode } = this.appSettingsStore;
    const { addGlobalMessage } = this.systemMessageStore;
    const { locationValue, setIsLocationsLoading, setLabs, setLocations, setLocationValue } = this.sessionsStore;

    const allLabs: LabType[] = [];
    const allLocations: KeyTextPair[] = [];

    setIsLocationsLoading(true);

    isDebugMode && console.log('[SessionsViewModel:loadLocations] Loading lab locations.');

    // Disabling this as Sessions don't work for AIR
    /* if (!isPartnerMode) {
      const location: KeyTextPair = { key: FilterOptions.TEAM_NAME, text: FilterOptions.TEAM_NAME };

      allLocations.push(location);
      isDebugMode && console.log(`[SessionsViewModel:loadLocations] Added ${FilterOptions.TEAM_NAME}.`);
    } */

    try {
      const labs: LabType[] = await ganymedeLabRequestService.getLabs();

      allLabs.push(...labs);

      if (labs?.length > 0) {
        const labLocations = labs.map((lab: LabType) => ({ key: String(lab.LabId), text: lab.LabName }));

        allLocations.push(...labLocations);
      }

      isDebugMode && console.log(`[SessionsViewModel:loadLocations] Found ${labs.length} labs.`);
    } catch (error) {
      const apiErrorMessage: string = format(t('sessions-labs-error', { ns: NS.ERRORS }));
      console.error(apiErrorMessage, error.message, error);

      // Append an error to the message bar.
      const message: SystemMessageType = {
        message: apiErrorMessage,
        id: 'sessions-labs-error',
        type: FluentMessageBarType.error,
        mode: MessageBarMode.normal,
        namespace: NS.ERRORS,
        groupId: 'sessions-labs-error',
      };

      addGlobalMessage(message);
    } finally {
      setIsLocationsLoading(false);
    }

    allLocations.sort((a: KeyTextPair, b: KeyTextPair) => a.text.localeCompare(b.text));

    setLabs(allLabs);
    setLocations(allLocations);

    if (locationValue.trim() === '') {
      if (allLocations.length > 0) {
        setLocationValue(allLocations[0].key);
      }
    }

    isDebugMode && console.log(`[SessionsViewModel:loadLocations] Total ${allLabs.length} labs, ${allLocations.length} locations.`);
  };

  public loadSessions = async (
    latestRequestIdRef: React.MutableRefObject<number>,
    requestId: number,
    pageSize: number,
    pageNumber: number,
    filter: any,
    cancelToken: CancelToken,
  ): Promise<void> => {
    const { isDebugMode } = this.appSettingsStore;
    const { locationValue, setSessions, setIsSessionsLoading } = this.sessionsStore;
    const { addGlobalMessage } = this.systemMessageStore;
    const sessions: SessionType[] = [];

    isDebugMode && console.log(`[SessionsViewModel] Fetching Session ${requestId}.`);

    try {
      const labId: string = locationValue.toString();
      const isValidLab = !isNaN(Number(labId));

      if (!isValidLab) {
        // No data to load. Clear the list.
        setSessions(sessions);
      } else {
        // Start both API calls in parallel
        const sessionsPromise = GanymedeSessionRequestService.getExperimentSessions(
          labId,
          filter,
          pageSize,
          pageNumber,
          cancelToken,
        );

        const statusesPromise = GanymedeSessionRequestService.getExperimentSessionsStatus(
          labId,
          filter,
          pageSize,
          pageNumber,
          cancelToken,
        );

        // Await the sessions data to display immediately
        sessionsPromise
          .then((sessionsResponse) => {
            this.loadSessionsHelper(latestRequestIdRef, requestId, pageSize, pageNumber, sessionsResponse, true);
          })
          .catch((error) => {
            console.error('Error fetching session statuses data:', error);
          });

        // Await the status data and update UI once it's available
        statusesPromise
          .then((statusesResponse) => {
            this.loadSessionsHelper(latestRequestIdRef, requestId, pageSize, pageNumber, statusesResponse, false);
          })
          .catch((error) => {
            console.error('Error fetching session statuses data:', error);
          });
      }
    } catch (error) {
      console.error('[SessionsViewModel] Error fetching Sessions:', error);

      const message: string = error.message;
      const systemMessage: SystemMessageType = {
        id: 'sessions-api-error',
        message,
        type: FluentMessageBarType.error,
        mode: MessageBarMode.normal,
        namespace: NS.ERRORS,
        groupId: 'sessions-api-group',
      };

      setSessions(sessions);
      setIsSessionsLoading(false);
      addGlobalMessage(systemMessage);
    }
  };

  public setCompanyName = async () => {
    const { labs, locationValue, sessions, setLabCompanyName } = this.sessionsStore;

    if (sessions?.length > 0 && labs?.length > 0) {
      const labExists: boolean = labs.some((f) => String(f.LabId) === locationValue);

      if (labExists) {
        const selectedLab: LabType = labs?.find((f) => String(f.LabId) === locationValue);

        const companies: any[] = await ganymedeLabRequestService.getCompanies();
        const labCompany: any = companies.find((activeCompany) => activeCompany.CompanyId === selectedLab.CompanyId);

        setLabCompanyName(labCompany?.CompanyName);
      }
    }
  };

  public setGroupByData = (tableData: any[], groupByField?: string) => {
    const columnDefinitions = SessionDetailsStore.SESSION_COLUMN_DEFINITIONS;
    const { setSessions, setSessionGroups, setGroupByColumn, groupByValue } = this.sessionsStore;

    const groupByColumn = groupByField ?? groupByValue;
    const { returnData, groups } = setTableDataGroupBy(tableData, groupByColumn, columnDefinitions);

    setSessions(returnData);
    setSessionGroups(groups);
    setGroupByColumn(groupByColumn);
  };

  private loadSessionsHelper = (
    latestRequestIdRef: React.MutableRefObject<number>,
    requestId: number,
    pageSize: number,
    pageNumber: number,
    sessionsResponse: any,
    clearSessionsData: boolean,
  ) => {
    const { paginationType, setPaginationType } = this.paginationStore;
    const { setSessions, setTotalSessionsCount, setIsSessionsLoading } = this.sessionsStore;
    const sessions: SessionType[] = [];

    // If we have not previously selected a session, clear the list and behave normally.
    if (clearSessionsData) {
      setIsSessionsLoading(true);
      setSessions([]);
    }

    // Ignore all non-current requests.
    if (latestRequestIdRef.current === requestId) {
      const sessionsCount: number = sessionsResponse.count;

      setTotalSessionsCount(sessionsCount);

      if (sessionsCount > 0) {
        for (const row of sessionsResponse.experimentSessions) {
          const session: SessionType & SessionGUIType = SessionsViewModel.buildSession(this.rootStore, row);

          if (session) {
            sessions.push(session);
          }
        }
      }

      const { paginationProps, ...prevPaginationDefaults } = paginationType || {};

      const updatedPaginationType: PaginationType = {
        ...prevPaginationDefaults,
        paginationProps: {
          ...paginationProps,
          pageSize,
          currentPage: pageNumber,
          totalItems: sessionsCount,
          hasFiltersApplied: this.hasFiltersApplied(),
        },
      };

      setPaginationType(updatedPaginationType);
      this.setGroupByData(sessions);
      setIsSessionsLoading(false);
    }
  };
}

export default SessionsViewModel;
