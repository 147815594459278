import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { DefaultButton, IconButton, PrimaryButton, TooltipHost } from '@fluentui/react';
import { PanelType } from '@fluentui/react/lib/Panel';
import { useId } from '@fluentui/react-hooks';
import { t } from 'i18next';

import { ExperimentType } from '@/components/Experiments/ExperimentsTypes';
import { Defaults } from '@/constants/AirConstants';
import { SystemIcons } from '@/constants/IconConstants';
import { Navigation } from '@/constants/NavigationConstants';
import { Namespaces as NS, SystemType } from '@/constants/SystemConstants';
import { onDismissType } from '@/partials/MessageBar/MessageBarTypes';
import { TITLE_DIVIDER } from '@/partials/PageHeader/PageHeaderConstants';
import SidePanelStyles from '@/partials/SidePanel/SidePanelStyles';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { copyToClipboard } from '@/utils/Helpers';

import ExperimentPropertiesPanelTemplate from './ExperimentPropertiesPanelTemplate';
import { ExperimentPropertiesPanelVMType } from './ExperimentPropertiesPanelTypes';

import styles from './ExperimentPropertiesPanel.module.css';

interface ExperimentPropertiesPanelVCProps {
  viewModel: ExperimentPropertiesPanelVMType;
}

const ExperimentPropertiesPanelViewControllerFC: React.FC<ExperimentPropertiesPanelVCProps> = ({ viewModel }) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { experimentDetailsStore } = rootStore;
  const { experimentDetails, closeExperimentPanel, isExperimentPanelOpen, openExperimentModal, isAirExperiment } =
    experimentDetailsStore;
  const { loadMessage } = viewModel;

  const [content, setContent] = React.useState<React.ReactElement>(<></>);
  const [localMessage, setLocalMessage] = React.useState<string>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [pathname, setPathname] = React.useState<string>('');
  const [activeKey, setActiveKey] = React.useState('');
  const tooltipId = useId('tooltip');
  const status = experimentDetails?.status;

  const copyButton = (value: string): React.ReactElement => {
    const title = t('copy-to-clipboard', { ns: NS.COMMON });
    const icon = { iconName: SystemIcons.COPY };
    const onClick = () => copyToClipboard(value);

    return (
      <div className={styles['copy-button']}>
        <TooltipHost content={title} id={tooltipId}>
          <IconButton aria-label={title} iconProps={icon} onClick={onClick} />
        </TooltipHost>
      </div>
    );
  };

  const title =
    `${t('experiment', { ns: NS.DEFAULT })}` +
    (experimentDetails ? ` ${TITLE_DIVIDER} ${experimentDetails.name} ${TITLE_DIVIDER} ${experimentDetails.instanceId}` : '');

  // Build the footer content.
  const onRenderFooterContent: () => React.ReactElement = React.useCallback(() => {
    return (
      <div className={styles['footer-container']}>
        <PrimaryButton
          type="button"
          onClick={closeExperimentPanel}
          text={t('close', { ns: NS.COMMON })}
          title={t('close-panel', { ns: NS.COMMON })}
          ariaLabel={t('close-panel', { ns: NS.COMMON })}
          className={styles.button}
        ></PrimaryButton>

        <DefaultButton
          type="button"
          onClick={openExperimentModal}
          text={t('open-full-view', { ns: NS.EXPERIMENTS })}
          title={t('open-full-view', { ns: NS.EXPERIMENTS })}
          ariaLabel={t('open-full-view', { ns: NS.EXPERIMENTS })}
          className={styles.button}
        ></DefaultButton>

        <Link
          to={{
            pathname: pathname,
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <DefaultButton
            iconProps={{ iconName: SystemIcons.OPEN_IN_NEW_WINDOW }}
            type="button"
            text={t('open-new-window', { ns: NS.EXPERIMENTS })}
            title={t('open-new-window', { ns: NS.EXPERIMENTS })}
            ariaLabel={t('open-new-window', { ns: NS.EXPERIMENTS })}
            className={styles.button}
            disabled={!experimentDetails}
          ></DefaultButton>
        </Link>
      </div>
    );
  }, [pathname, experimentDetails]);

  const userSettings = {
    headerText: title,
    type: PanelType.custom,
    isOpen: isExperimentPanelOpen,
    onDismiss: closeExperimentPanel,
    onRenderFooterContent: onRenderFooterContent,
    styles: SidePanelStyles.experimentInfo,
  };

  const onLinkClick = (item: React.ReactElement) => {
    setActiveKey(item.props.itemKey);
  };

  const onDismissMessage: onDismissType = () => {
    setLocalMessage(null);
  };

  const resetPanel = (row: ExperimentType, status: string) => {
    let pathname;
    const labId = row.labId || SystemType.AIR;
    const message = loadMessage || null;

    if (isAirExperiment) {
      // NOTE: This is a temporary solution until we have data that will give us better AIR results.
      const teamName = Defaults.teamName;
      const goalId = experimentDetails?.instanceId || experimentDetails?.id;
      const experimentName = experimentDetails?.json?.['definition']?.['experimentName'];

      pathname = `${Navigation.AIR.SCHEDULER_STATUS}/${teamName}/${goalId}/${experimentName}`;
    } else {
      pathname = `${Navigation.GANYMEDE.EXPERIMENTS}/${row.type}/${labId}/${row.instanceId}`;
    }

    setPathname(pathname);
    setLocalMessage(message); // Clear any messages in this Panel.
  };

  React.useEffect(() => {
    experimentDetails && resetPanel(experimentDetails, status);
  }, [experimentDetails, status, loadMessage]);

  return (
    <ExperimentPropertiesPanelTemplate
      content={content}
      setContent={setContent}
      localMessage={localMessage}
      setLocalMessage={setLocalMessage}
      onDismissMessage={onDismissMessage}
      copyButton={copyButton}
      isLoading={isLoading}
      activeKey={activeKey}
      onLinkClick={onLinkClick}
      userSettings={userSettings}
    ></ExperimentPropertiesPanelTemplate>
  );
};

const ExperimentPropertiesPanelViewController = observer(ExperimentPropertiesPanelViewControllerFC);

export default ExperimentPropertiesPanelViewController;
