import React from 'react';
import { Namespace, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Icon } from '@fluentui/react';
import { t } from 'i18next';
import styled from 'styled-components';

import { NavigationIcon } from '@/constants/NavigationConstants';
import { Namespaces as NS } from '@/constants/SystemConstants';
import { NavLinkItemFTType, NavLinkItemType } from '@/partials/NavLinkItem/NavLinkItemTypes';

import styles from './NavLinkItem.module.css';

const defaultIndentPixels = 20;

type StyledDivType = {
  level: number;
};

const getPaddingLeft = (level: number) => {
  return level * defaultIndentPixels;
};

const StyledDiv = styled.div`
  padding-left: ${(props: StyledDivType) => getPaddingLeft(props.level + 1)}px;
`;

const NavLinkFC: React.FC<NavLinkItemFTType> = (props: NavLinkItemFTType): React.ReactElement => {
  const {
    location,
    link,
    endpoint,
    iconName,
    level,
    childLinks,
    isCollapsed,
    onToggle,
    links,
    pathCheck,
    getLinkClass,
    getChildLinks,
  } = props;
  const allMatch = pathCheck(location, link.key).allMatch;

  const item: React.ReactElement = (
    <>
      <Icon className={`${styles['link-icon']}`} iconName={iconName} />
      {!isCollapsed && (
        <div className={`${styles['link-text']}`}>
          <Trans ns={NS.NAVIGATION as Namespace}>{endpoint}</Trans>
        </div>
      )}
    </>
  );
  const linkClass = getLinkClass(location, endpoint, isCollapsed, level);

  return (
    <>
      <StyledDiv className={linkClass} key={link.key} level={level} data-level={level}>
        {/* TODO: (24262777)
        We have to do some work here to have a potentially better/different experience. 
        We need to potentially modify this code to possibly open and close a section/folder, which 
        will lead to a discussion or require a technical analysis.
        */}
        <Link
          className={`${styles['link-navigation']}`}
          key={endpoint}
          to={endpoint}
          onClick={() => {
            return onToggle(link);
          }}
          title={t(endpoint, { ns: NS.NAVIGATION })}
        >
          {item}
        </Link>
      </StyledDiv>

      {/* Currently, we only show the child nodes (NavLinkItems) when the Sidebar is not collapsed and they are selected. */}
      {allMatch &&
        childLinks?.map((navLink: NavLinkItemType) => {
          const childLinks: NavLinkItemType[] | undefined = getChildLinks(navLink, links);
          const key: string = navLink.key;
          const iconName = NavigationIcon[key as string];

          return (
            <NavLinkTemplate
              location={location}
              link={navLink}
              links={links}
              key={key}
              endpoint={key}
              childLinks={childLinks}
              iconName={iconName}
              level={level + 1}
              isCollapsed={isCollapsed}
              onToggle={onToggle}
              pathCheck={pathCheck}
              getLinkClass={getLinkClass}
              getChildLinks={getChildLinks}
            />
          );
        })}
    </>
  );
};

const NavLinkTemplate = observer(NavLinkFC);

export default NavLinkTemplate;
