import React from 'react';
import { observer } from 'mobx-react-lite';

import { BadgeType } from './BadgeTypes';

import styles from './Badge.module.css'; // Apply any SideBar level CSS Module styling here.

const BadgeFC: React.FC<BadgeType> = (props: BadgeType): React.ReactElement => {
  const { children } = props;

  return <div className={styles.badge}>{children}</div>;
};

const Badge = observer(BadgeFC);

export default Badge;
