import React from 'react';
import { observer } from 'mobx-react-lite';

import NavLinkItemTemplate from '@/partials/NavLinkItem/NavLinkItemTemplate';
import { NavLinkItemFCType, NavLinkItemType } from '@/partials/NavLinkItem/NavLinkItemTypes';

import styles from './NavLinkItem.module.css';

const NavLinkItemFC: React.FC<NavLinkItemFCType> = (props: NavLinkItemFCType): React.ReactElement => {
  const { location, link, endpoint, iconName, level, childLinks, isCollapsed, onToggle, onClick, links } = props;

  const pathCheck = (
    userPath: string,
    navLinkPath: string,
  ): {
    rootPath: string;
    firstPath: string;
    secondPath: string;
    fullPath: string;
    rootMatch: boolean;
    firstMatch: boolean;
    secondMatch: boolean;
    fullPathMatch: boolean;
    allMatch: boolean;
  } => {
    const pathArray = userPath.split('/'); // Splits up a URL that originally could be "/projects/path/test/one"

    // Check to see how we can highlight the NavLinkItem.
    const rootPath = `/${pathArray[1]}`; // Converts the URL to "/projects"
    const firstPath = `/${pathArray[1]}/${pathArray[2]}`; // Converts the URL "/projects/path"
    const secondPath = `/${pathArray[1]}/${pathArray[2]}/${pathArray[3]}`; // Converts the URL "/projects/path/sub-path"
    const fullPath = pathArray.join('/');

    // Check to see how we can highlight the NavLinkItem.
    const rootMatch = navLinkPath === rootPath;
    const firstMatch = navLinkPath === firstPath;
    const secondMatch = navLinkPath === secondPath;
    const fullPathMatch = navLinkPath === fullPath;

    const allMatch = rootMatch || firstMatch || secondMatch || fullPathMatch;

    return { rootPath, firstPath, secondPath, fullPath, rootMatch, firstMatch, secondMatch, fullPathMatch, allMatch };
  };

  const getLinkClass = (userPath: string, navLinkPath: string, isCollapsed: boolean, level: number): string => {
    // Check to see how we can highlight the NavLinkItem.
    const { rootMatch, firstMatch, secondMatch, fullPathMatch, rootPath, firstPath, secondPath } = pathCheck(userPath, navLinkPath);
    const isSecondaryLevel = level > 0; // We are checking if this is Level 1 or higher.
    const onRootPath = rootMatch && userPath === navLinkPath;

    const linkClass = `${styles['link']} 
      ${onRootPath ? styles['link-active'] : ''}  
      ${firstMatch ? styles['link-active'] : ''} 
      ${secondMatch ? styles['link-warm'] : ''} 
      ${fullPathMatch ? styles['link-warm'] : ''}
      ${isCollapsed ? styles['link-is-collapsed'] : ''}
      ${isSecondaryLevel ? styles['link-is-child'] : ''}
      level-${level}
    `;

    return linkClass.replace(/\s+/g, ' ')?.trim();
  };

  const getChildLinks = (navLink: NavLinkItemType, links: NavLinkItemType[]): NavLinkItemType[] | undefined => {
    const children = navLink.children;

    if (children?.length > 0) {
      return links.filter((child: NavLinkItemType) => children.includes(child.key));
    }

    return undefined;
  };

  return (
    <NavLinkItemTemplate
      location={location}
      link={link}
      links={links}
      childLinks={childLinks}
      endpoint={endpoint}
      iconName={iconName}
      level={level}
      isCollapsed={isCollapsed}
      onToggle={onToggle}
      onClick={onClick}
      pathCheck={pathCheck}
      getLinkClass={getLinkClass}
      getChildLinks={getChildLinks}
    />
  );
};

const NavLinkItem = observer(NavLinkItemFC);

export default NavLinkItem;
