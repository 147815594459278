import { OSImagesType } from '@/components/MachineSelect/MachineSelectTypes';
import LabDetailsStore from '@/components/ManageLab/LabDetails/LabDetailsStore';
import { Company, LabDetailsType, OsImageType } from '@/components/ManageLab/LabGeneral/LabGeneralTypes';
import { Defaults } from '@/constants/LabsConstants';
import { FailGroupIds, KeyTextPair, Namespaces as NS, SystemConstants } from '@/constants/SystemConstants';
import { labsRequestService } from '@/services/_labs/request-services';
import { ganymedeLabRequestService } from '@/services/request-services/LabRequestService';
import { ganymedeTagRequestService } from '@/services/request-services/TagRequestService';
import AppSettingsStore from '@/stores/AppSettingsStore';
import { RootStore } from '@/stores/RootStore';
import SystemMessageStore from '@/stores/SystemMessageStore';
import { HandleError } from '@/utils/_labs/HandleError';

import LabGeneralStore from './LabGeneralStore';

class LabGeneralViewModel {
  protected appSettingsStore: AppSettingsStore;
  protected labDetailsStore: LabDetailsStore;
  protected labGeneralStore: LabGeneralStore;
  protected systemMessageStore: SystemMessageStore;

  constructor(rootStore: RootStore) {
    const { appSettingsStore, labDetailsStore, labGeneralStore, systemMessageStore } = rootStore;

    this.appSettingsStore = appSettingsStore;
    this.labDetailsStore = labDetailsStore;
    this.labGeneralStore = labGeneralStore;
    this.systemMessageStore = systemMessageStore;
  }

  getOsImages = async (): Promise<void> => {
    const { setOsImageList } = this.labGeneralStore;

    await ganymedeLabRequestService
      .getOSImages()
      .then((result) => {
        if (result?.length > 0) {
          const mappedData: OsImageType[] = this.buildDataMapping(result);

          setOsImageList(mappedData);
          this.populateOSDropdown();
        }
      })
      .catch((error) => {
        const handleErrorProps = {
          error,
          appSettingsStore: this.appSettingsStore,
          failGroupId: FailGroupIds.LAB_OS_IMAGES,
        };

        HandleError(handleErrorProps);
      });
  };

  onCompanyChange = (event: React.FormEvent<HTMLDivElement>, item: KeyTextPair): void => {
    const { osImageList, setCompanyValue } = this.labGeneralStore;

    const companyId = Number(item.key);

    setCompanyValue(companyId);

    if (companyId !== SystemConstants.MS_COMPANY_ID) {
      this.handleExternalCompany(osImageList);
    } else {
      this.handleInternalCompany(osImageList);
    }
  };

  handleExternalCompany = (osImageList: OsImageType[]): void => {
    const { setIsInternal } = this.labGeneralStore;

    setIsInternal(false);
    this.populateOSDropdown();
  };

  handleInternalCompany = (osImageList: OsImageType[]): void => {
    const { setIsInternal } = this.labGeneralStore;

    setIsInternal(true);
    this.populateOSDropdown();
  };

  getCompanyNameById = async (companyId: number): Promise<string> => {
    const companies: Company[] = await ganymedeLabRequestService.getCompanies();

    const labCompany: Company = companies.find((activeCompany) => activeCompany.CompanyId === companyId);

    return labCompany.CompanyName;
  };

  fetchCompanyName = async (companyId: number): Promise<string> => {
    const companyName = await this.getCompanyNameById(companyId);

    return companyName;
  };

  getOsFriendlyNameById = (id: number): string => {
    const { osImageList } = this.labGeneralStore;

    const os = osImageList.filter((osImage) => osImage.imageId === id);

    return os.length > 0 ? os[0].friendlyName : '';
  };

  fetchExistingTags = async (): Promise<void> => {
    const { setTagOptions } = this.labGeneralStore;

    await ganymedeTagRequestService
      .getTags()
      .then((result) => {
        setTagOptions(result);
      })
      .catch((error) => {
        const handleErrorProps = {
          error,
          appSettingsStore: this.appSettingsStore,
          failGroupId: FailGroupIds.LAB_CONTROL,
        };

        HandleError(handleErrorProps);
      });
  };

  getLastHeartBeat = async (labId: number): Promise<Date | null> => {
    try {
      const response = await labsRequestService.getAgentHeartbeat(labId.toString());

      return response.lastModified;
    } catch {
      return null;
    }
  };

  getLabUser = async (): Promise<void> => {
    const { setLabUserList, setAdminMenu } = this.labGeneralStore;

    const { selectedLab } = this.labDetailsStore;

    ganymedeLabRequestService
      .getLabsUser(selectedLab.LabId)
      .then((response) => {
        if (response) {
          setLabUserList(response);
        }
      })
      .catch(() => {
        setAdminMenu(false);
      });
  };

  getLabAdmins = async (labId): Promise<any> => {
    const response = await ganymedeLabRequestService.getLabsUser(labId);

    if (response) {
      const labAdminList = response.filter((user) => user.RoleName === Defaults.LAB_ADMIN);

      return labAdminList;
    } else {
      return [];
    }
  };

  mapLabDetailsData = async (selectedLab): Promise<void> => {
    const { setOsImageValue, setLabDetailsData } = this.labGeneralStore;

    const companyName = await this.fetchCompanyName(selectedLab.CompanyId);

    const heartBeat = await this.getLastHeartBeat(selectedLab.LabId);

    const labAdminList = await this.getLabAdmins(selectedLab.LabId);

    setOsImageValue(selectedLab.DefaultOsImageId.toString());

    const mappedData: LabDetailsType = {
      labId: selectedLab.LabId,
      labName: selectedLab.LabName,
      labStatus: selectedLab.LabStatus,
      companyName,
      lastHeartBeat: heartBeat,
      version: selectedLab.Version,
      isVirtualLab: selectedLab.IsVirtualLab,
      defaultOsImage: this.getOsFriendlyNameById(selectedLab.DefaultOsImageId),
      labAdminList,
    };

    setLabDetailsData(mappedData);
  };

  protected populateOSDropdown = (imageList?: OsImageType[]): void => {
    const { osImageFilteredList, setOsImages } = this.labGeneralStore;

    const options: KeyTextPair[] = [];

    imageList = imageList || osImageFilteredList;

    imageList.map((image: OsImageType) => options.push({ key: image.imageId.toString(), text: image.friendlyName }));

    setOsImages(options);
  };

  protected buildDataMapping = (results: OSImagesType[]): OsImageType[] => {
    const mappedData: OsImageType[] = results.map((result: OSImagesType) => ({
      imageId: result.OsImageId,
      imageName: result.OsImageName,
      friendlyName: result.OsImageFriendlyName,
      imageBuild: result.OsImageBuild,
      description: result.OsImageDescription,
      isInternal: result.IsInternal,
    }));

    return mappedData;
  };
}

export default LabGeneralViewModel;
