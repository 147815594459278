const LogsView = {
  DOWNLOAD_TO_VIEW: 'download-to-view',
  LOGS: 'logs',
  LOGS_NOT_FOUND: 'logs-not-found',
  LOGS_NOT_FOUND_TEXT: 'logs-not-found-text',
  PREVIEW_NOT_AVAILABLE: 'preview-not-available',
};

const Common = {
  CLOSE: 'close',
  CREATE: 'create',
  DELETE: 'delete',
  DOWNLOAD_FAILURE_TEMPLATE: 'download-failure-template',
  DUPLICATE_DATA: 'duplicate-data',
  EDIT_COLUMNS: 'edit-columns',
  ENTER_FIELD: 'enter-field',
  INVALID_EMAIL: 'invalid-email',
  INVALID_IP: 'invalid-ip',
  NO_ITEMS: 'no-items',
  REFRESH: 'refresh',
  RESULTS: 'results',
  SAVE: 'save',
  SEARCH_TEXT: 'search-text',
  SEARCH_USER_PLACEHOLDER: 'search-user-placeholder',
  SELECT_USER: 'select-user',
};

const Default = {
  IP_ADDRESS: 'ip-address',
};

const PageCommandBar = {
  GROUPBY_NONE: 'group-by-none',
};

const PaginationAria = {
  FIRST_PAGE: 'first-page',
  LAST_PAGE: 'last-page',
  NEXT_PAGE: 'next-page',
  PAGE_SIZE_TEXT: 'page-size-text',
  PREVIOUS_PAGE: 'previous-page',
};

const Results = {
  COPY_URL: 'copy-url',
  DOWNLOAD_FILE: 'download-file',
  DOWNLOAD_LOGS: 'download-logs',
  DOWNLOAD_START: 'download-start',
  DOWNLOAD_TEXT: 'download-text',
  LARGE_FILE_MESSAGE: 'large-file-message',
  RESULT_ANALYZER: 'result-analyzer',
  RESULT_EXPLORER: 'result-explorer',
  ZIP_WARNING_TEMPLATE: 'zip-warning-message-template',
};

const Labs = {
  ADD_ADMIN: 'add-admin',
  ADMIN: 'admin',
  ALL_LABS: 'all-labs',
  COMPANY: 'company',
  CREATE_LAB: 'create-lab',
  CREATE_LAB_SUCCESS: 'create-lab-success',
  DELETE_LAB: 'delete-lab',
  DELETE_SUCCESS_TEMPLATE: 'delete-success-template',
  DOWNLOAD_EXE: 'download-exe',
  DOWNLOAD_INSTALLER: 'download-installer',
  DOWNLOAD_MSI: 'download-msi',
  DOWNLOAD_WAIT_TIME_REQUEST: 'download-wait-time-request',
  FIX_IP_ADDRESS: 'fix-ip-address',
  FIX_MAC_ADDRESS: 'fix-mac-address',
  IP_ADDRESS: 'ip-address',
  IP_ADDRESS_ERROR: 'ip-address-error',
  LAB_NAME: 'lab-name',
  LAB_NAME_MISMATCH: 'lab-name-mismatch',
  LAB_NAME_TO_DELETE: 'lab-name-to-delete',
  LABS: 'labs',
  MAC_ADDRESS: 'mac-address',
  MAC_ADDRESS_ERROR: 'mac-address-error',
  MANAGE_USERS: 'manage-users',
  NEW_IP_ADDRESS: 'new-ip-address',
  NEW_MAC_ADDRESS: 'new-mac-address',
  OLD_IP_ADDRESS: 'old-ip-address',
  OLD_MAC_ADDRESS: 'old-mac-address',
  OS_IMAGE: 'os-image',
  PASSWORD_REFERENCE: 'password-reference',
  REPAIR_MACHINE: 'repair-machine',
  REPAIR_REQUEST_ERROR_TEMPLATE: 'repair-request-error-template',
  REPAIR_REQUEST_SUCCESS_TEMPLATE: 'repair-request-success-template',
  SARM_ADD_SUCCESS_TEMPLATE: 'sarm-add-success-template',
  SARM_DELETE: 'sarm-delete',
  SARM_DELETE_CONFIRMATION_TEMPLATE: 'sarm-delete-confirmation-template',
  SARM_DELETE_SUCCESS_TEMPLATE: 'sarm-delete-success-template',
  SARM_IP: 'sarm-ip',
  SARM_NOT_ASSOCIATED_TEMPLATE: 'sarm-not-associated-template',
  SARM_SUB_TITLE: 'sarm-sub-title',
  SARM_UPDATE: 'sarm-update',
  SAVE_LAB: 'save-lab',
  SEARCH_LAB: 'search-lab',
  SELECT_COMPANY: 'select-company',
  SELECT_LAB_ADMIN: 'select-lab-admin',
  SELECT_OS_IMAGE: 'select-os-image',
  UPDATE_LAB: 'update-lab',
  UPDATE_LAB_CONFIRMATION: 'update-lab-confirmation',
  USERNAME: 'username',
  VIEW_ALL_IMAGES: 'view-all-images',
  VIEW_LAB: 'view-lab',
};

const Experiments = {
  VIEW_EXPERIMENT_STATUS: 'view-experiment-status',
};

export { Common, Default, Experiments, Labs, LogsView, PageCommandBar, PaginationAria, Results };
