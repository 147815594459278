import React from 'react';
import { observer } from 'mobx-react-lite';

import { RootStore, RootStoreContext } from '@/stores/RootStore';

import SessionsViewController from './SessionsViewController';
import SessionsViewModel from './SessionsViewModel';

const SessionsFC: React.FC = () => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { systemMessageStore } = rootStore;
  const { addHelpMessage, clearNonPersistentGlobalMessages } = systemMessageStore;
  const viewModel = new SessionsViewModel(rootStore);

  const openPanelOnRowSelect = true;

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages, addHelpMessage]);

  return <SessionsViewController viewModel={viewModel} openPanelOnRowSelect={openPanelOnRowSelect}></SessionsViewController>;
};

const Sessions = observer(SessionsFC);

export default Sessions;
