import { action, computed, makeObservable, observable, toJS } from 'mobx';
import { t } from 'i18next';

import { LabType } from '@/components/Experiments/ExperimentsTypes';
import { CancellableStatuses, Statuses } from '@/constants/ExperimentConstants';
import { FilterOptions, KeyTextPair, Namespaces as NS, SystemType } from '@/constants/SystemConstants';
import { PageCommandBar } from '@/constants/TranslationConstants';
import AppSettingsService from '@/services/AppSettingsService';
import LocalStorageService from '@/services/LocalStorageService';
import { RootStore } from '@/stores/RootStore';
import { ActionTypeNull, ActionTypeVoid, NumberTypeAny } from '@/types/AppSettingsTypes';

import { LocationType, SessionStatusType, SessionType } from './SessionsTypes';

class SessionsStore {
  protected rootStore: RootStore;
  protected localStorage: LocalStorageService;

  protected defaultValue = 0;
  protected groupByDefault = t(PageCommandBar.GROUPBY_NONE, { ns: NS.COMMON });
  protected noLabIdKey = 'no-lab-id';
  protected now = new Date();

  public labCompanyName: string;
  public pathSessionId: string;
  public sessions: SessionType[] = [];
  public sessionGroups: any[] = [];
  public selectedSession: SessionType | null = null;
  public selectedSessions: SessionType[] = null;
  public pageSize: number;
  public totalSessionsCount: number;

  public endDate: Date;
  public groupByColumn: string;
  public groupByValue: string;
  public ipAddresses: string[];
  public ipAddressPairs: KeyTextPair[];
  public isIpAddressLoading: boolean;
  public isLocationsLoading: boolean;
  public isSessionsLoading: boolean;
  public labs: LabType[];
  public lastRunTimeRange: string;
  public locations: KeyTextPair[];
  public locationValue: string;
  public searchValue: string;
  public startDate: Date;
  public statusValues: string[];

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      // Observables for Sessions configuration not persisted.
      labCompanyName: observable,
      pathSessionId: observable, // Used to keep track of Session to support Deep Links.
      selectedSession: observable,
      selectedSessions: observable,
      sessions: observable,
      sessionGroups: observable,

      // These values are persisted.
      endDate: observable,
      groupByColumn: observable,
      groupByValue: observable,
      ipAddresses: observable,
      ipAddressPairs: observable,
      isIpAddressLoading: observable,
      isLocationsLoading: observable,
      isSessionsLoading: observable,
      labs: observable,
      lastRunTimeRange: observable,
      locations: observable,
      locationValue: observable,
      pageSize: observable,
      searchValue: observable,
      startDate: observable,
      statusValues: observable,
      totalSessionsCount: observable,

      // Actions modify the state.
      clearSelectedSession: action,
      clearSelectedSessions: action,
      doReset: action,
      resetLocation: action,
      setEndDate: action,
      setIpAddresses: action,
      setIpAddressPairs: action,
      setIsIpAddressLoading: action,
      setIsLocationsLoading: action,
      setIsSessionsLoading: action,
      setGroupByColumn: action,
      setGroupByValue: action,
      setLabCompanyName: action,
      setLabs: action,
      setLastRunTimeRange: action,
      setLocationValue: action,
      setLocations: action,
      setPageSize: action,
      setSessions: action,
      setSessionGroups: action,
      setTotalSessionsCount: action,
      setSearchValue: action,
      setSelectedSession: action,
      setSelectedSessions: action,
      setStartDate: action,
      setStatusValues: action,

      // Set computed properties.
      hasAtLeastOneSession: computed,
      hasMultipleSelectedSessions: computed,
      hasSelectedSessions: computed,
      isSessionSelected: computed,
      isViewingAir: computed,
      isViewingDeepLink: computed,
      isViewingLab: computed,
      selectedLabId: computed,
      selectedSessionIsCancellable: computed,
      selectedSessionsAreCancellable: computed,
      selectedSessionStatusType: computed,
    });

    const { localStorage } = rootStore;

    this.rootStore = rootStore;
    this.localStorage = localStorage;

    this.pageSize =
      this.localStorage.getValue(AppSettingsService.SESSION_PAGE_SIZE_KEY) || AppSettingsService.DEFAULT_SESSION_PAGE_SIZE;
    this.searchValue = this.localStorage.getValue(AppSettingsService.FILTER_NAME_SEARCH_KEY) || '';
    this.labs = [];
    this.locations = [];
    this.locationValue = this.localStorage.getValue(AppSettingsService.FILTER_LOCATION_KEY) || '';
    this.statusValues = JSON.parse(this.localStorage.getValue(AppSettingsService.FILTER_STATUS_KEY)) || [];
    this.ipAddresses = JSON.parse(this.localStorage.getValue(AppSettingsService.FILTER_IP_ADDRESS_KEY)) || [];
    this.groupByValue =
      this.localStorage.getValue(AppSettingsService.FILTER_SESSIONS_GROUPBY_KEY) ||
      t(PageCommandBar.GROUPBY_NONE, { ns: NS.COMMON });
    this.startDate = new Date(this.localStorage.getValue(AppSettingsService.FILTER_START_DATE_KEY)) || this.now;
    this.endDate = new Date(this.localStorage.getValue(AppSettingsService.FILTER_END_DATE_KEY)) || this.now;
    this.lastRunTimeRange = this.localStorage.getValue(AppSettingsService.FILTER_LAST_RUNTIME_KEY) || FilterOptions.ALL;

    this.save();
  }

  public setLocations = (value: KeyTextPair[]): KeyTextPair[] => {
    this.locations = value;

    return this.locations;
  };

  public setPathSessionId = (value: string) => {
    this.pathSessionId = value?.trim();

    return this.pathSessionId;
  };

  public setSessions = (rows: SessionType[] | null): SessionType[] | null => {
    this.sessions = rows;

    return this.sessions;
  };

  public setSessionGroups = (value: any[]): any[] => {
    this.sessionGroups = value;

    return this.sessionGroups;
  };

  public setTotalSessionsCount = (value: number) => {
    this.totalSessionsCount = value;

    return this.totalSessionsCount;
  };

  public setSelectedSession = (row: SessionType | null): SessionType | null => {
    this.selectedSession = row;

    return this.selectedSession;
  };

  public setSelectedSessions = (rows: SessionType[]): SessionType[] => {
    this.selectedSessions = rows || null;

    return this.selectedSessions;
  };

  public setGroupByColumn = (value: string) => {
    this.groupByColumn = value;

    return this.groupByColumn;
  };

  public setGroupByValue = (value: string) => {
    this.groupByValue = value;

    return this.localStorage.setValue(AppSettingsService.FILTER_SESSIONS_GROUPBY_KEY, value);
  };

  public setLabCompanyName = (name: string) => {
    this.labCompanyName = name;
  };

  public setLabs = (values: LabType[]): LabType[] => {
    this.labs = values;

    return this.labs;
  };

  public setLocationValue = (value: string) => {
    this.locationValue = value;

    return this.localStorage.setValue(AppSettingsService.FILTER_LOCATION_KEY, value);
  };

  public setIpAddresses = (values: string[]): string[] => {
    this.ipAddresses = values;

    return this.localStorage.setValue(AppSettingsService.FILTER_IP_ADDRESS_KEY, JSON.stringify(values));
  };

  public setIpAddressPairs = (values: KeyTextPair[]): KeyTextPair[] => {
    this.ipAddressPairs = values;

    return this.ipAddressPairs;
  };

  public setIsIpAddressLoading = (value: boolean) => {
    this.isIpAddressLoading = value;

    return this.isIpAddressLoading;
  };

  public setIsLocationsLoading = (value: boolean) => {
    this.isLocationsLoading = value;

    return this.isLocationsLoading;
  };

  public setIsSessionsLoading = (value: boolean) => {
    this.isSessionsLoading = value;

    return this.isSessionsLoading;
  };

  public setPageSize: NumberTypeAny = (value: number) => {
    this.pageSize = value;

    return this.localStorage.setValue(AppSettingsService.PAGE_SIZE_KEY, value);
  };

  public setSearchValue = (value: string) => {
    this.searchValue = value;

    return this.localStorage.setValue(AppSettingsService.FILTER_NAME_SEARCH_KEY, value);
  };

  public setStatusValues = (values: string[]) => {
    this.statusValues = values;

    return this.localStorage.setValue(AppSettingsService.FILTER_STATUS_KEY, JSON.stringify(values));
  };

  public setStartDate = (value: Date) => {
    this.startDate = new Date(value);
    this.localStorage.setValue(AppSettingsService.FILTER_START_DATE_KEY, value);
  };

  public setEndDate = (value: Date) => {
    this.endDate = new Date(value);
    this.localStorage.setValue(AppSettingsService.FILTER_END_DATE_KEY, value);
  };

  public setLastRunTimeRange = (value: string) => {
    this.lastRunTimeRange = value;

    return this.localStorage.setValue(AppSettingsService.FILTER_LAST_RUNTIME_KEY, value);
  };

  public resetLocation = (location: string) => {
    this.setLocationValue(location);
    this.setSearchValue('');
    this.setIpAddresses([]);
    this.setStatusValues([]);
  };

  public doReset: ActionTypeVoid = () => {
    this.clearSelectedSessions();
    this.setSearchValue('');
    this.setStatusValues([]);
    this.setIpAddresses([]);
    this.setGroupByValue(this.groupByDefault);
    this.setStartDate(this.now);
    this.setEndDate(this.now);
    this.setLastRunTimeRange(FilterOptions.ALL);
    this.save();
  };

  protected save: ActionTypeNull = () => {
    this.localStorage.setValue(AppSettingsService.PAGE_SIZE_KEY, this.pageSize);

    this.localStorage.setValue(AppSettingsService.FILTER_NAME_SEARCH_KEY, this.searchValue);
    this.localStorage.setValue(AppSettingsService.FILTER_LOCATION_KEY, this.locationValue);
    this.localStorage.setValue(AppSettingsService.FILTER_STATUS_KEY, JSON.stringify(this.statusValues));
    this.localStorage.setValue(AppSettingsService.FILTER_IP_ADDRESS_KEY, JSON.stringify(this.ipAddresses));
    this.localStorage.setValue(AppSettingsService.FILTER_SESSIONS_GROUPBY_KEY, this.groupByValue);
    this.localStorage.setValue(AppSettingsService.FILTER_START_DATE_KEY, this.startDate);
    this.localStorage.setValue(AppSettingsService.FILTER_END_DATE_KEY, this.endDate);
    this.localStorage.setValue(AppSettingsService.FILTER_LAST_RUNTIME_KEY, this.lastRunTimeRange);

    return null;
  };

  public get hasAtLeastOneSession(): boolean {
    const match: boolean = this.sessions?.length > 0 || false;

    return match;
  }

  public get hasMultipleSelectedSessions(): boolean {
    const match: boolean = this.selectedSessions?.length > 1 || false;

    return match;
  }

  public get hasSelectedSessions(): boolean {
    // This is true is we have a non-zero amount of selected sessions.
    const match: boolean = !!this.selectedSessions?.length || false;

    return match;
  }

  public get isSessionSelected(): boolean {
    const match = !!this.selectedSession;

    return match;
  }

  public get isViewingAir(): boolean {
    // The locationValue is a string and cannot be converted to a number.
    const match = isNaN(Number(this.locationValue));

    return match;
  }

  public get isViewingDeepLink(): boolean {
    // The pathSessionId is not empty.
    const match = !!this.pathSessionId;

    return match;
  }

  public get isViewingLab(): boolean {
    // The locationValue can be converted to a number.
    const match = !isNaN(Number(this.locationValue));

    return match;
  }

  public get selectedLabId(): string {
    const selectedSession: SessionType = this.selectedSession;
    const location: LocationType = selectedSession?.location;
    const labId: string = location?.labId?.toString() || this.noLabIdKey;

    return labId;
  }

  public get selectedSessionStatusType(): string {
    const status: string = this.selectedSession?.status?.final.toLowerCase();

    if (!status) {
      return Statuses.UNKNOWN;
    }

    // Iterate through the Statuses object and see if any value matches the status string.
    // If we have a match, return the value, otherwise return unknown.
    const matchStatus = (key: string) => Statuses[key as string].toLowerCase() === status;
    const statusKey: string = Object.keys(Statuses).find(matchStatus);
    const statusType: string = Statuses[statusKey as string] || Statuses.UNKNOWN;

    return statusType;
  }

  public get selectedSessionIsCancellable(): boolean {
    // Returns true if the selected Session has a status that is Cancellable.
    if (!this.selectedSession) {
      return false;
    }

    const status: string = this.selectedSessionStatusType;
    const isCancellable: boolean = CancellableStatuses.includes(status);

    return isCancellable;
  }

  public get selectedSessionsAreCancellable(): boolean {
    // Returns true if all selected Sessions have a status that is Cancellable.
    if (!this.hasSelectedSessions) {
      return false;
    }

    const match: boolean = this.selectedSessions.every((row: SessionType) => {
      const statusType: SessionStatusType = row.status;
      const status: string = statusType?.final?.toLowerCase() || Statuses.UNKNOWN;
      const isCancellable: boolean = CancellableStatuses.includes(status);

      return isCancellable;
    });

    return match;
  }

  public clearSelectedSession: ActionTypeVoid = () => {
    // Clear only the selected Session.
    this.setSelectedSession(null);
    this.setPathSessionId(null);
  };

  public clearSelectedSessions: ActionTypeVoid = () => {
    // Clear both the selected session objects.
    this.setSelectedSession(null);
    this.setSelectedSessions(null);
    this.setPathSessionId(null);
  };
}

export default SessionsStore;
