import { LabType } from '@/components/Experiments/ExperimentsTypes';
import LabDetailsStore from '@/components/ManageLab/LabDetails/LabDetailsStore';
import { ganymedeLabRequestService } from '@/services/request-services/LabRequestService';
import AppSettingsStore from '@/stores/AppSettingsStore';
import { RootStore } from '@/stores/RootStore';
import SystemMessageStore from '@/stores/SystemMessageStore';
import { HandleError } from '@/utils/_labs/HandleError';

class LabDetailsViewModel {
  protected appSettingsStore: AppSettingsStore;
  protected labDetailsStore: LabDetailsStore;
  protected systemMessageStore: SystemMessageStore;

  constructor(rootStore: RootStore) {
    const { appSettingsStore, labDetailsStore, systemMessageStore } = rootStore;

    this.appSettingsStore = appSettingsStore;
    this.labDetailsStore = labDetailsStore;
    this.systemMessageStore = systemMessageStore;
  }

  fetchLabs = async (): Promise<void> => {
    const { setBusy, setLabs, setSelectedLab } = this.labDetailsStore;

    try {
      const response: LabType[] = await ganymedeLabRequestService.getLabs();

      if (response?.length > 0) {
        setLabs(response);
        setSelectedLab(response[0]); // Set the first lab as the default selected lab
      }
    } catch (error) {
      const handleErrorProps = {
        error,
        appSettingsStore: this.appSettingsStore,
      };

      HandleError(handleErrorProps);
    } finally {
      setBusy(false);
    }
  };
}

export default LabDetailsViewModel;
