import { CancelToken } from 'axios';

import { ExperimentTemplate, ExperimentTemplateInfo } from '@/components/ExperimentEditor/ExperimentEditorTypes';
import { ExperimentRequestType } from '@/components/Experiments/ExperimentsTypes';
import { DataType } from '@/constants/SystemConstants';

import BaseRequestService from './RequestService';

class ExperimentRequestService extends BaseRequestService {
  async getExperimentTemplatesInfoBySchema(
    schema: string,
    queryParams?: object,
    cancelToken?: CancelToken,
  ): Promise<ExperimentTemplateInfo[]> {
    const url = `templates/schemas/${schema}`;
    const response = await this.get(url, queryParams, { cancelToken });

    return response?.data as ExperimentTemplateInfo[];
  }

  async getExperimentTemplateByName(name: string, queryParams?: object, cancelToken?: CancelToken): Promise<ExperimentTemplate> {
    const url = `templates/${name}`;
    const response = await this.get(url, queryParams, { cancelToken });

    return response?.data as ExperimentTemplate;
  }

  async getExperimentDetails(queryParams: ExperimentRequestType, cancelToken?: CancelToken) {
    const url = 'experiments/list';
    const response = await this.post(url, queryParams, { cancelToken });

    return response?.data;
  }

  async queueExperiment(
    labId: string,
    experimentJson: string,
    targetMachineIds: any,
    pairMachines: boolean,
    cancelToken?: CancelToken,
  ) {
    const payload = {
      ExperimentJSON: { experimentJson },
      MachineIds:
        targetMachineIds.length > 1 || typeof targetMachineIds === DataType.OBJECT ? targetMachineIds : [targetMachineIds],
      PairMachines: pairMachines,
    };

    const url = `experiments/queue/${Number(labId)}`;
    const { data } = await this.post(url, payload, {
      cancelToken,
    });

    return data;
  }

  async previewExperiment(experimentJson: string, cancelToken?: CancelToken) {
    const url = 'experiments/queue/preview';
    const { data } = await this.post(url, experimentJson, {
      cancelToken,
    });

    return data;
  }

  async getExperimentSteps(experimentInstanceId: string, cancelToken?: CancelToken) {
    const url = `steps/${experimentInstanceId}`;
    const { data } = await this.get(url, {
      cancelToken,
    });

    return data;
  }

  async getIPAddress(labId: number, cancelToken?: CancelToken) {
    const url = `lab-systems/${labId}/ip-addresses`;
    const { data } = await this.get(url, { cancelToken });

    return data;
  }

  async getExperimentInstanceDetails(labId: any, experimentInstanceId: any, cancelToken?: CancelToken) {
    const url = `experiments/${experimentInstanceId}/labs/${labId}`;
    const { data } = await this.get(url, null, { cancelToken });
    const list = data?.length > 0 ? data : [];

    return list;
  }

  async cancelExperiment(experimentInstanceId: any, cancelToken?: CancelToken) {
    const url = `experiments/${experimentInstanceId}/cancel`;
    const { data } = await this.put(url, null, { cancelToken });

    return data;
  }
}

export const ganymedeExperimentRequestService = new ExperimentRequestService();
