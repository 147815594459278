import React from 'react';
import { observer } from 'mobx-react-lite';
import { PanelType } from '@fluentui/react';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { t } from 'i18next';

import CreateLabViewModel from '@/components/CreateLab/CreateLabViewModel';
import { Namespaces as NS } from '@/constants/SystemConstants';
import { Common, Labs } from '@/constants/TranslationConstants';
import SidePanelStyles from '@/partials/SidePanel/SidePanelStyles';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

import LabPanelTemplate from './LabPanelTemplate';

const LabPanelViewControllerFC: React.FC = () => {
  // Store const
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { labPanelStore } = rootStore;
  const { isLabPanelOpen, closeLabPanel } = labPanelStore;

  const createLabViewModel = React.useMemo(() => new CreateLabViewModel(rootStore), [rootStore]);
  const { saveLab } = createLabViewModel;

  const onRenderFooterContent: () => React.ReactElement = React.useCallback(() => {
    return <PrimaryButton text={t(Common.CREATE, { ns: NS.COMMON })} onClick={saveLab} />;
  }, [saveLab]);

  const userSettings = {
    headerText: t(Labs.CREATE_LAB, { ns: NS.LABS }),
    type: PanelType.custom,
    isOpen: isLabPanelOpen,
    onDismiss: closeLabPanel,
    styles: SidePanelStyles.createLabPanel,
    onRenderFooterContent: onRenderFooterContent,
  };

  return <LabPanelTemplate userSettings={userSettings} createLabViewModel={createLabViewModel}></LabPanelTemplate>;
};

const LabPanelViewController = observer(LabPanelViewControllerFC);

export default LabPanelViewController;
