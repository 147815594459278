import React from 'react';
import { observer } from 'mobx-react-lite';
import { Pivot } from '@fluentui/react';
import { PivotItem } from '@fluentui/react';

import { LabDetailsVCType } from '@/components/ManageLab/LabDetails/LabDetailsTypes';
import { Namespaces as NS, TabMemoryKeys } from '@/constants/SystemConstants';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

const LabDetailsTemplateFC: React.FC<LabDetailsVCType> = (props: LabDetailsVCType) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore } = rootStore;

  const { components, activeTab } = props;
  const pageName = TabMemoryKeys.LAB_DETAILS;
  const { setTabMemory } = appSettingsStore;

  return (
    <>
      <Pivot defaultSelectedKey={activeTab} onLinkClick={(item) => setTabMemory(pageName, item.props.itemKey)}>
        {components.map(({ headerText, viewModel, component: Component }, index) => (
          <PivotItem key={index} itemKey={headerText} headerText={headerText}>
            <Component viewModel={viewModel} />
          </PivotItem>
        ))}
      </Pivot>
    </>
  );
};

export const LabDetailsTemplate = observer(LabDetailsTemplateFC);
