import React from 'react';
import { observer } from 'mobx-react-lite';
import { Modal } from '@fluentui/react';

import { RootStore, RootStoreContext } from '@/stores/RootStore';

import ExperimentDetails from '../ExperimentDetails/ExperimentDetailsWrapper';
import { ExperimentInstanceModalVCType } from './ExperimentInstanceModalTypes';

import styles from './ExperimentInstanceModal.module.css';

const ExperimentInstanceModalTemplateFC: React.FC<ExperimentInstanceModalVCType> = (props: ExperimentInstanceModalVCType) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { experimentDetailsStore } = rootStore;
  const { isExperimentModalOpen, closeExperimentModal } = experimentDetailsStore;
  const { title, labId, instanceId, localMessage, userSettings } = props;

  return (
    <>
      {isExperimentModalOpen && (
        <Modal
          className="ganymede-wrapper"
          titleAriaId="editor-confirmation"
          isOpen={isExperimentModalOpen}
          isBlocking={false}
          containerClassName={styles['modal-container']}
          onDismiss={closeExperimentModal}
        >
          <ExperimentDetails title={title} labId={labId} instanceId={instanceId} loadMessage={localMessage}></ExperimentDetails>
          <div className={styles['modal-footer']}>{userSettings.onRenderFooterContent}</div>
        </Modal>
      )}
    </>
  );
};

const ExperimentInstanceModalTemplate = observer(ExperimentInstanceModalTemplateFC);

export default ExperimentInstanceModalTemplate;
