import React from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { Icon } from '@fluentui/react';
import { MessageBarType, TooltipHost } from '@fluentui/react';
import { ITooltipHostStyles } from '@fluentui/react/lib/Tooltip';
import saveAs from 'file-saver';
import { t } from 'i18next';
import ls from 'local-storage';
import format from 'string-template';
import { utils, write } from 'xlsx';

import { DateFormats } from '@/constants/DateFormatConstants';
import { StatusClasses, Statuses, StatusIcons } from '@/constants/ExperimentConstants';
import { Navigation } from '@/constants/NavigationConstants';
import {
  DataType,
  Delimiters,
  FailGroupIds,
  Files,
  FileType,
  FilterOptions,
  HealthDiffInMins,
  InfoGroupIds,
  KeyTextPair,
  Labels,
  Namespaces as NS,
  RegExpPatterns,
  SuccessGroupIds,
} from '@/constants/SystemConstants';
import { Common, Labs, Results } from '@/constants/TranslationConstants';
import { labsRequestService } from '@/services/_labs/request-services';
import { ganymedeLabRequestService } from '@/services/request-services/LabRequestService';
import SystemMessageStore from '@/stores/SystemMessageStore';
import { SystemMessageType } from '@/types/SystemMessageTypes';

import { labsBackendEndpoint, resultsExplorerEndpoint } from './Env';
import MsalAuthorization from './MsalAuthorization';

import styles from '@/components/Experiments/Experiments.module.css';

const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
};

const extractDefinition = (json: any): any => {
  // Only grabs the "definition" property from the given item.
  const getDefinition = (item: any) => {
    return item['definition'] || {};
  };

  if (Array.isArray(json) && json.length > 0) {
    // We must only take the first definition from the json (and there is only ever one item).
    return getDefinition(json[0]);
  } else if (typeof json === DataType.OBJECT) {
    return getDefinition(json);
  } else {
    // We have an invalid type of data.
    console.error('[extractDefinition] Invalid json input type. Expected an object or an array of objects.');

    // Since we had an error, return what we were given.
    return json;
  }
};

const extractString = (element: any): string => {
  if (typeof element === DataType.STRING) {
    return element;
  } else if (React.isValidElement(element)) {
    return extractString((element as React.ReactElement).props.children);
  } else if (Array.isArray(element)) {
    return element.map((e) => extractString(e)).join(' ');
  } else {
    return element?.toString() || '';
  }
};

const filterInternalProperties = (json: Record<string, any>): Record<string, any> => {
  // Removes all top-level properties that start with an underscore ("_"), whether the data
  // is an object (AIR data), or an array of objects (Labs Data).
  const filterChar = Delimiters.UNDERSCORE;

  const filterObject = (item: any) => {
    const results = {};

    for (const [key, value] of Object.entries(item)) {
      if (!key.startsWith(filterChar)) {
        results[key as string] = value;
      }
    }

    return results;
  };

  if (Array.isArray(json)) {
    return json.map((item) => {
      return filterObject(item);
    });
  } else if (typeof json === DataType.OBJECT) {
    return filterObject(json);
  } else {
    // We have an invalid type of data.
    console.error('[filterInternalProperties] Invalid json input type. Expected an object or an array of objects.');

    // Since we had an error, return what we were given.
    return json;
  }
};

const filterIndicator = (filtersApplied: boolean, records: number) => {
  const fullDataMessage = format(t('viewing-all-records', { ns: NS.EXPERIMENTS }), {
    count: records,
  });
  const filteredDataMessage = format(t('viewing-filtered-records', { ns: NS.EXPERIMENTS }), {
    count: records,
  });

  const output = filtersApplied ? filteredDataMessage : fullDataMessage;

  return output;
};

const formatJsonResponse = (data: any): string => {
  if (!data) {
    return '';
  }

  try {
    const result = JSON.stringify(data, null, 2)
      // Remove any newlines or carriage returns found in our data.
      .replace(/\\r\\n/g, '')
      // We must preserve all backslash characters in our JSON, by escaping, otherwise the user
      // will be viewing an escaped version of data, which will not copy and paste correctly
      // into a JSON template. This will display to the user exactly what came in from the API.
      .replaceAll(/\\/g, '\\\\');

    return result;
  } catch (error) {
    console.error('[formatJsonResponse] error:', error);

    throw new Error(error);
  }

  return '';
};

const modifiedColumnConfiguration = (config: any): any => {
  const columnsConfig = config.map((column: any) => {
    if (column.fieldName === Labels.STATUS) {
      return {
        ...column,
        onRender: (item: any) => statusCell(item.status),
      };
    }

    return column;
  });

  return columnsConfig;
};

const navigationOnClick = (event, url, history) => {
  if ((event.ctrlKey && event.button === 0) || event.button === 1) {
    window.open(url, '_blank');
  } else if (event.button === 0) {
    history.push(url);
  }
};

const openTestUrlInNewTab = async (
  instanceId?: string,
  definitionId?: string,
  labId?: number,
  isPartnerMode?: boolean,
  company?: string,
) => {
  let companyName = company;
  const urlParam = definitionId ? 'experimentDefinitionId=' + definitionId : 'experimentInstanceId=' + instanceId;

  if (companyName === null) {
    const labData = await ganymedeLabRequestService.getLabDetails(labId);
    const companyId = labData.CompanyId;
    const labs = await ganymedeLabRequestService.getCompanies();
    const filteredLab = labs.filter((value) => value.CompanyId === companyId);
    companyName = filteredLab[0].CompanyName;
  }

  const url = `${Navigation.LABS.TEST_RUN}/${urlParam}&companyName=${companyName}`;

  openPageInNewTab(url);
};

const openPageInNewTab = async (url: string) => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

// TODO: Currently, the status property is a string, but it should be an enum.
// This enum would then be translated to show the appropriate text. This would ultimately touch a lot
// of places, so we will create a ticket to address this in the future.
// https://msazure.visualstudio.com/One/_workitems/edit/28735216

const statusCell = (status: string) => {
  let iconName: string;
  let statusClass: string;

  const myStatus: string = status?.toLowerCase();

  // If an icon does not match our given icons, we will default to the "failed" icon.
  if (Object.values(Statuses).includes(myStatus)) {
    iconName = StatusIcons[myStatus as string];
  } else {
    iconName = StatusIcons[Statuses.FAILED];
  }

  // If a status does not match our given statuses, we will default to the "cancelled" class.
  if (Object.values(StatusClasses).includes(myStatus)) {
    statusClass = `status-${StatusClasses[myStatus as string]}`;
  } else {
    statusClass = `status-${StatusClasses[Statuses.CANCELLED]}`;
  }

  return (
    <div className={`${styles['status']} ${styles[statusClass as string]}`}>
      {status !== FilterOptions.NO_STATUS && <Icon className={styles['status-icon']} iconName={iconName} />}
      <span className={`${styles['status-value']}`}>{status}</span>
    </div>
  );
};

const exportToExcel = (data, fileName) => {
  const worksheet = utils.json_to_sheet(data);
  const workbook = utils.book_new();

  utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  const excelBuffer = write(workbook, { type: 'array' });
  const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

  saveAs(excelBlob, `${fileName}.xlsx`);
};

const translateName = (inputColumns: any[]): any[] => {
  const tColumns = inputColumns.map((inputColumn) => {
    return { ...inputColumn, name: t(inputColumn.name, { ns: NS.TABLE }) };
  });

  return tColumns;
};

const getUniqueList = (items: any[], key: string, formatter?: string, defaultText?: string): KeyTextPair[] => {
  const values = items?.map((item) => item[key as string]) ?? [];
  const uniqueValuesSet = new Set(values);
  const uniqueValuesArray = Array.from(uniqueValuesSet);

  const uniqueValues: KeyTextPair[] = uniqueValuesArray.map((value) => ({
    key: value,
    text: formatter && value === formatter ? defaultText : value,
  }));

  return uniqueValues;
};

const downloadUrl = async (
  url: any,
  filename: string,
  systemMessageStore: SystemMessageStore,
  failGroupId: string,
  isResultLogDownload = false,
) => {
  const xhr = new XMLHttpRequest();

  xhr.open('GET', url);
  xhr.onreadystatechange = handler;
  xhr.responseType = 'blob';

  if (filename === Files.LOGS_ZIP || isResultLogDownload) {
    xhr.setRequestHeader('Authorization', `Bearer ${(ls as any).get('resultsToken')}`);
  } else {
    const token = await MsalAuthorization.getToken();

    xhr.setRequestHeader('Authorization', `Bearer ${token}`);
  }

  xhr.send();

  function handler(this: any) {
    if (this.readyState === this.DONE) {
      if (this.status === 200) {
        const element = document.createElement('a');

        element.setAttribute('href', URL.createObjectURL(this.response));
        element.setAttribute('download', filename);
        element.style.display = 'none';
        element.click();
      } else {
        const downloadFailMessage = format(t(Common.DOWNLOAD_FAILURE_TEMPLATE, { ns: NS.COMMON }), {
          url: url,
        });

        const { addGlobalMessage } = systemMessageStore;

        const failMessage: SystemMessageType = {
          message: downloadFailMessage,
          type: MessageBarType.error,
          groupId: failGroupId,
        };

        addGlobalMessage(failMessage);
      }
    }
  }
};

// To download logs of results
const logDownloadHelper = (path: string, systemMessageStore: SystemMessageStore, companyName?: string, showInPopup = false) => {
  const { addGlobalMessage } = systemMessageStore;
  const outputFile = Files.LOGS_ZIP;

  const successMessage: SystemMessageType = {
    message: t(Results.DOWNLOAD_START, { ns: NS.RESULTS }),
    type: MessageBarType.info,
    groupId: SuccessGroupIds.LOG_DOWNLOAD,
    showInPopup,
  };

  addGlobalMessage(successMessage);

  const downloadWarningMessage = format(t(Results.ZIP_WARNING_TEMPLATE, { ns: NS.RESULTS }), {
    zip: FileType.SEVEN_ZIP,
  });

  const infoMessage: SystemMessageType = {
    message: downloadWarningMessage,
    type: MessageBarType.info,
    groupId: InfoGroupIds.LOG_DOWNLOAD,
    showInPopup,
  };

  addGlobalMessage(infoMessage);

  const baseUrl = resultsExplorerEndpoint;
  const downloadPath = companyName
    ? `${baseUrl}/file/download/folder?resultCollectionId=${path}&companyName=${companyName}`
    : `${baseUrl}/file/download/folder?resultCollectionId=${path}`;

  downloadUrl(downloadPath, outputFile, systemMessageStore, FailGroupIds.LOG_DOWNLOAD);
};

const labDownloadHelper = (labId: number, systemMessageStore: SystemMessageStore, isExe: boolean): void => {
  const { addGlobalMessage } = systemMessageStore;
  const baseUrl: string = labsBackendEndpoint;

  const download = (path: string, file: string) => {
    downloadUrl(`${baseUrl}${path}`, file, systemMessageStore, FailGroupIds.LAB_DOWNLOAD);
  };

  if (isExe) {
    addGlobalMessage({
      message: t(Labs.DOWNLOAD_WAIT_TIME_REQUEST, { ns: NS.LABS }),
      type: MessageBarType.info,
      groupId: InfoGroupIds.LAB_DOWNLOAD,
    });

    download(`LabInstaller/${labId}/LabInstaller.exe`, Files.LAB_INSTALLER_EXE);
  } else {
    download(`LabInstaller/${labId}/CRCLabsSetup.msi`, Files.CRC_LABS_SETUP_MSI);
  }

  download(`LabInstallerConfig/${labId}`, Files.CRC_LABS_CONFIG_JSON);
};

const createNavigationLink = (fieldData: string, navigation: string): JSX.Element => {
  const link = (
    <>
      <Link to={navigation}>{fieldData}</Link>
    </>
  );
  return link;
};

const getToolkitCommand = (json: any): string | null => {
  const normalizedJson = normalizeJsonKeys(json);
  const definitionParameters: any = normalizedJson.definition?.parameters;

  if (definitionParameters) {
    const profile: string = definitionParameters.profile?.toLowerCase();
    const parameters: string = definitionParameters.parameters;
    const commandKey = 'ToolkitCommand=';

    if (profile === Files.TOOL_KIT) {
      const paramsArray: string[] = parameters?.split(',');

      for (const param of paramsArray) {
        if (param.includes(commandKey)) {
          return param.split('=')[1];
        }
      }
    }
  }

  return null;
};

const getLogPath = (json: any, toolkitCommand: string): string | null => {
  toolkitCommand = toolkitCommand?.toLowerCase();

  const instanceItem: any = json[0];
  const definition: any = instanceItem?.definition;
  const metadata: any = instanceItem?.definition?.metadata;
  const id: string = instanceItem?.id;
  const name: string = definition?.name?.toLowerCase();
  const experimentDefinitionId: string = metadata?.experimentDefinitionId;
  const revision: string = metadata?.revision?.toLowerCase();
  const fields: string[] = [experimentDefinitionId, name, id, revision, toolkitCommand];

  if (fields.every(Boolean)) {
    const logPath = `${experimentDefinitionId}~${name}~${id},${revision}~${toolkitCommand}`;

    return logPath;
  }

  return null;
};

const normalizeJsonKeys = (json: any): any => {
  if (Array.isArray(json)) {
    return json.map(normalizeJsonKeys);
  } else if (json?.constructor === Object) {
    return Object.keys(json).reduce((accumulator, key) => {
      accumulator[key.toLowerCase()] = normalizeJsonKeys(json[key as string]);
      return accumulator;
    }, {} as Record<string, any>);
  }

  return json;
};

const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };

const populateHealthStatus = (item: any, location: any, styles?: any) => {
  const currentDate = new Date().getTime();
  const heartbeat = new Date(`${item}Z`).getTime();
  const diffInMs = Math.abs(currentDate - heartbeat) / 60000;
  let labHealth;
  let labHealthText;
  let labStatusIcon;

  if (item === null || item === undefined) {
    labHealth = Labels.UNHEALTHY;
    labHealthText = Labels.LAB_UNHEALTHY;
    labStatusIcon = Labels.STATUS_ERROR_FULL;
  } else if (item === Labels.UNKNOWN) {
    labHealth = Labels.UNKNOWN;
    labHealthText = Labels.LAB_UNKNOWN;
    labStatusIcon = Labels.STATUS_UNKNOWN;
  } else if (diffInMs <= HealthDiffInMins.WARNING) {
    labHealth = Labels.HEALTHY;
    labHealthText = Labels.LAB_HEALTHY;
    labStatusIcon = Labels.SKYPE_CIRCLE_CHECK;
  } else if (diffInMs <= HealthDiffInMins.UNHEALTHY) {
    labHealth = Labels.WARNING;
    labHealthText = Labels.LAB_WARNING;
    labStatusIcon = Labels.SKYPE_CIRCLE_CHECK;
  } else {
    labHealth = Labels.UNHEALTHY;
    labHealthText = Labels.LAB_UNHEALTHY;
    labStatusIcon = Labels.STATUS_ERROR_FULL;
  }

  return (
    <TooltipHost
      content={
        item === null || item === undefined ? (
          <span>
            {Labels.LAST_HEARTBEAT}
            {Labels.NA}
          </span>
        ) : (
          <span>
            {Labels.LAST_HEARTBEAT}
            <Moment format={DateFormats.MEDIUM_INFO_DATE_FORMAT}>{new Date(`${item}Z`)}</Moment>
          </span>
        )
      }
      id={`tooltip-${item}`}
      styles={hostStyles}
    >
      {styles ? (
        <p className="status pointer" aria-describedby="tooltip">
          {location === 'main' ? Labels.LAB_HEALTH : null}
          <Icon iconName={labStatusIcon} className={`${styles[labHealthText as string]}`} />
          <b className={`${styles[labHealthText as string]}`}>{labHealth}</b>
        </p>
      ) : (
        <p className="status pointer" aria-describedby="tooltip">
          {location === 'main' ? Labels.LAB_HEALTH : null}
          <Icon iconName={labStatusIcon} className={labHealth} />
          <b className={labHealthText}>{labHealth}</b>
        </p>
      )}
    </TooltipHost>
  );
};

const isValidEmail = (
  email: string,
  systemMessageStore: SystemMessageStore,
  failGroupId: string,
  showMessageInPopup = true,
): boolean => {
  const { addGlobalMessage } = systemMessageStore;

  const isValidEmail = isValidEmailFormat(email);

  if (!isValidEmail) {
    const failMessage: SystemMessageType = {
      message: t(Common.INVALID_EMAIL, { ns: NS.COMMON }),
      type: MessageBarType.error,
      groupId: failGroupId,
      showInPopup: showMessageInPopup,
    };

    addGlobalMessage(failMessage);
    return false;
  }

  return true;
};

const isValidEmailFormat = (email: string): boolean => {
  const emailRegex: RegExp = RegExpPatterns.EMAIL;
  return emailRegex.test(email);
};

export {
  copyToClipboard,
  createNavigationLink,
  downloadUrl,
  exportToExcel,
  extractDefinition,
  extractString,
  filterIndicator,
  filterInternalProperties,
  formatJsonResponse,
  getLogPath,
  getToolkitCommand,
  getUniqueList,
  hostStyles,
  isValidEmail,
  isValidEmailFormat,
  labDownloadHelper,
  logDownloadHelper,
  modifiedColumnConfiguration,
  navigationOnClick,
  openPageInNewTab,
  openTestUrlInNewTab,
  populateHealthStatus,
  statusCell,
  translateName,
};
