import React from 'react';

const numberDefaults = {
  minWidth: 25,
  maxWidth: 40,
};

const renderStyles = {
  root: {
    '.root-132': {
      'padding-top': 0,
    },
    selectors: {
      '.ms-DetailsHeader-cell': {
        whiteSpace: 'normal',
        textOverflow: 'clip',
        lineHeight: 'normal',
      },
      '.ms-DetailsHeader-cellTitle': {
        height: '100%',
        alignItems: 'center',
      },
    },
  },
};

const tableDefaults = {
  data: 'string',
  isColumnSort: true,
  isCompact: false,
  isGroupBy: false,
  isHidden: false,
  isMultiline: true,
  isPadded: true,
  isResizable: true,
  isRowHeader: false,
  isSortedDescending: true,
  sortAscendingAriaLabel: 'sorted-ascending',
  sortDescendingAriaLabel: 'sorted-descending',
};

const stringDefaults = {
  minWidth: 100,
};

export { numberDefaults, renderStyles, stringDefaults, tableDefaults };
