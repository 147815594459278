import React from 'react';
import { observer } from 'mobx-react-lite';
import { DefaultButton, Icon, IconButton, MessageBarType, Modal, Persona, PersonaSize, Toggle } from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';

import { contentStyles, iconButtonStyles } from '@/components/_labs/LabsHelper/Modal';
import { LabUser } from '@/components/CreateLab/CreateLabType';
import { SystemIcons } from '@/constants/IconConstants';
import { AreaLabels, Buttons, Labels, Messages } from '@/constants/LabsConstants';
import { EventKeys, FailGroupIds, Namespaces as NS, SuccessGroupIds } from '@/constants/SystemConstants';
import MessageBarTemplate from '@/partials/MessageBar/MessageBarTemplate';
import { TagPickerComponent } from '@/partials/TagPicker/TagPickerComponent';
import { ganymedeLabRequestService } from '@/services/request-services/LabRequestService';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { SystemMessageType } from '@/types/SystemMessageTypes';
import { HandleError } from '@/utils/_labs/HandleError';
import { isValidEmail } from '@/utils/Helpers';

import { ConfirmationComponent } from './ConfirmationComponent';

import '@/styles/Labs.css';
import '@/components/_labs/LabsHelper/LabsHelper.css';

const PeoplePickerComponentFC = (props: any) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, systemMessageStore } = rootStore;
  const { isDarkMode } = appSettingsStore;
  const { addGlobalMessage, clearNonPersistentGlobalMessages, globalMessages } = systemMessageStore;

  const [label, setLabel] = React.useState<string>('');
  const addUserLabTitleId = useId('addLabUserTitle');
  const addTestplanUserTitleId = useId('addTestplanUserTitle');
  const [titleId, setTitleId] = React.useState<string>('');
  const [allUser, setAllUser] = React.useState<string[]>([]);
  const [usersList, setUsersList] = React.useState<any[]>([]);
  const [selectedUser, setSelectedUser] = React.useState<any[]>([]);
  const [deleteUser, setDeleteUser] = React.useState([]);
  const [isDeleteLabsUserModalOpen, { setTrue: showDeleteLabsUserModal, setFalse: hideDeleteLabsUserModal }] = useBoolean(false);

  const [name, setName] = React.useState<string>('');
  const [isAdmin, setIsAdmin] = React.useState<boolean>(false);

  const cancelIcon = { iconName: SystemIcons.CANCEL };
  const successGroupId: string = SuccessGroupIds.PEOPLE_PICKER;
  const failGroupId: string = FailGroupIds.PEOPLE_PICKER;

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages]);

  const getUsers = React.useCallback(async () => {
    await ganymedeLabRequestService
      .getUsers()
      .then((response: any) => {
        if (response) {
          setAllUser(response);
        }
      })
      .catch((error) => {
        const handleErrorProps = {
          error,
          systemMessageStore,
          appSettingsStore,
          failGroupId,
          showInPopup: true,
        };

        HandleError(handleErrorProps);
      });
  }, []);

  React.useEffect(() => {
    getUsers();

    if (props.action === Labels.addLabUser) {
      setTitleId(addUserLabTitleId);
      setUsersList(props.labUserList);
    }
  }, [addUserLabTitleId, addTestplanUserTitleId, getUsers, props]);

  const addUserTotheLab = async (evt: any) => {
    evt.preventDefault();

    if (selectedUser.length === 0 && name) {
      const labUser: LabUser = {
        UserEmail: name,
        IsAdmin: isAdmin,
      };

      ganymedeLabRequestService
        .addUser(props.selectedItemKey, labUser)
        .then(() => {
          addSuccessMessage(Messages.successfullyAddedLabsUser);
          props.getLabUser();
        })
        .catch((error) => {
          const handleErrorProps = {
            error,
            systemMessageStore,
            appSettingsStore,
            failGroupId,
            showInPopup: true,
          };

          HandleError(handleErrorProps);
        });

      return;
    }

    await Promise.all(
      selectedUser.map((user) => {
        const labUser: LabUser = {
          UserEmail: user,
          IsAdmin: isAdmin,
        };

        ganymedeLabRequestService
          .addUser(props.selectedItemKey, labUser)
          .then(() => {
            addSuccessMessage(Messages.successfullyAddedLabsUser);
            props.getLabUser();
          })
          .catch((error) => {
            const handleErrorProps = {
              error,
              systemMessageStore,
              appSettingsStore,
              failGroupId,
              showInPopup: true,
            };

            HandleError(handleErrorProps);
          });
      }),
    );
  };

  const addSuccessMessage = (message: string) => {
    const successMessage: SystemMessageType = {
      message: message,
      type: MessageBarType.success,
      groupId: successGroupId,
      showInPopup: true,
    };

    addGlobalMessage(successMessage);
  };

  const handleChange = (event: any) => {
    setName(event.target.value);
  };

  const handleSwitchChange = (ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
    setIsAdmin(checked);
  };

  const handleDeleteUser = async (user: any) => {
    setDeleteUser(user);

    if (props.action === Labels.addLabUser) {
      showDeleteLabsUserModal();
    }
  };

  const populatePersona = () => {
    const method = addUserTotheLab;

    return (
      <>
        <div className="mt-4">
          {usersList.map((value, idx) => (
            <div key={idx} className="row">
              <div className="col-sm-9">
                <h5 id="manage-user">
                  <Persona
                    {...usersList}
                    text={props.action === Labels.addLabUser ? value.UserName : value}
                    size={PersonaSize.size40}
                  />
                </h5>
              </div>
              <div className="col-sm-3 mt-3 text-right">
                {props.action === Labels.addLabUser && value.RoleName === 'LabAdmin' ? (
                  'Lab Admin'
                ) : (
                  <h6
                    className="accent-color pointer"
                    onClick={() => handleDeleteUser(props.action === Labels.addLabUser ? value.UserName : value)}
                  >
                    {Labels.remove}
                  </h6>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="row mt-4">
          <div className="col-sm-12">
            <DefaultButton onClick={(e: any) => method(e)}>{Buttons.addUser}</DefaultButton>
          </div>
        </div>
      </>
    );
  };

  const getModalComponent = () => {
    if (isDeleteLabsUserModalOpen) {
      return (
        <ConfirmationComponent
          isConfirmModalOpen={isDeleteLabsUserModalOpen}
          hideConfirmModal={hideDeleteLabsUserModal}
          selectedItemKey={props.selectedItemKey}
          userName={deleteUser}
          fetchUsers={props.getLabUser}
          action={Labels.deleteLabsUser}
        />
      );
    }
  };

  React.useEffect(() => {
    setTitleId(addUserLabTitleId);
    setLabel(Messages.shareWithPeople);
  }, [addUserLabTitleId, props]);

  const isValidEmailAddress = (event: React.KeyboardEvent<HTMLDivElement>, newValue: string): boolean => {
    if (event.key.toLowerCase() === EventKeys.ENTER) {
      return isValidEmail(newValue, systemMessageStore, FailGroupIds.PEOPLE_PICKER);
    }

    return false;
  };

  return props.isModalOpen ? (
    <div>
      <Modal
        className="labs-wrapper"
        titleAriaId={titleId}
        isOpen={props.isModalOpen}
        isBlocking={false}
        containerClassName={contentStyles.firmwareContainer}
        onDismiss={props.hideModal}
      >
        <div className={contentStyles.header}>
          <h2 className={contentStyles.heading}>{props.title}</h2>
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel={AreaLabels.closePopupModal}
            onClick={props.hideModal}
          />
        </div>
        <div className={contentStyles.body}>
          <div className="row">
            <MessageBarTemplate>{globalMessages}</MessageBarTemplate>
            <div className="col-sm-8">
              <h5>
                <Icon iconName="AddFriend" className="circle-icon" />
                {label}
              </h5>
            </div>
            <div className="col-sm-4 text-right pr-2">
              <h5 className="text-grey mt-2">{props.titleName}</h5>
            </div>
          </div>
          <br />
          <div className="row">
            <div className={props.action === Labels.addLabUser ? 'col-lg-9 col-sm-8' : 'col-sm-12'}>
              <TagPickerComponent
                data={allUser}
                isDarkMode={isDarkMode}
                width="415px"
                onChange={(event, newValue) => {
                  setSelectedUser(newValue);
                }}
                onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>, newValue: string): boolean => {
                  return isValidEmailAddress(event, newValue);
                }}
                failMessageGroupId={failGroupId}
                showMessageInPopup={true}
              />
            </div>
            {props.action === Labels.addLabUser ? (
              <div className="col-lg-3 col-sm-4 text-left">
                <div className="pb-2">{Labels.permissionLevel}</div>
                <Toggle
                  checked={props.firmwareGroupVisibility}
                  offText={Labels.labUser}
                  onText={Labels.admin}
                  onChange={handleSwitchChange}
                />
              </div>
            ) : null}
          </div>
          {populatePersona()}
        </div>
      </Modal>
      {getModalComponent()}
    </div>
  ) : null;
};

export const PeoplePickerComponent = observer(PeoplePickerComponentFC);
