import React from 'react';
import { observer } from 'mobx-react-lite';

import SessionPanel from '@/components/SessionPanel/SessionPanelWrapper';
import ColumnEditorPanelTemplate from '@/partials/ColumnEditorPanel/ColumnEditorPanelTemplate';
import PageCommandBar from '@/partials/PageCommandBar/PageCommandBarTemplate';
import PageDivider from '@/partials/PageDivider/PageDivider';
import PageFilterBar from '@/partials/PageFilterBar/PageFilterBarTemplate';
import TableViewViewController from '@/partials/TableView/TableViewViewController';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { modifiedColumnConfiguration } from '@/utils/Helpers';

import { SessionsVCType } from './SessionsTypes';

import styles from './Sessions.module.css';

const SessionsTemplateFC: React.FC<SessionsVCType> = (props: SessionsVCType) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { paginationStore } = rootStore;
  const { paginationType } = paginationStore;
  const {
    config,
    tableItems,
    tableGroups,
    groupByColumn,
    groupByColumns,
    commandBarItems,
    overflowItems,
    filterItems,
    farItems,
    panelLoadErrorMessage,
    selection,
    selectionMode,
    selectionPreservedOnEmptyClick,
    checkboxVisibility,
    isLoading,
    isColumnEditorOpen,
    hideColumnEditor,
    columnsList,
    setColumnsList,
    entireColumns,
    columnEditorKey,
    columnEditorUserSettings,
    pageName,
  } = props;

  return (
    <>
      <PageCommandBar items={commandBarItems} overflowItems={overflowItems} farItems={farItems}></PageCommandBar>
      <PageDivider />
      <PageFilterBar items={filterItems}></PageFilterBar>
      <TableViewViewController
        items={tableItems}
        columns={modifiedColumnConfiguration(columnsList)}
        displayColumns={config.sessionsDisplayFields}
        groups={tableGroups}
        groupByColumn={groupByColumn}
        groupByColumns={groupByColumns}
        enableToolBar={false}
        enableColumnSort={true}
        tableStyle={`${styles['sessions-table']} ${styles['full-table']}`}
        selection={selection}
        selectionMode={selectionMode}
        selectionPreservedOnEmptyClick={selectionPreservedOnEmptyClick}
        checkboxVisibility={checkboxVisibility}
        isCompact={true}
        isStickyHeader={true}
        isLoading={isLoading}
        paginationType={paginationType}
        pageName={pageName}
      />
      <ColumnEditorPanelTemplate
        isColumnEditorOpen={isColumnEditorOpen}
        hideColumnEditor={hideColumnEditor}
        columnsList={columnsList}
        setColumnsList={setColumnsList}
        entireColumns={entireColumns}
        columnEditorKey={columnEditorKey}
        userSettings={columnEditorUserSettings}
      ></ColumnEditorPanelTemplate>
      <SessionPanel loadMessage={panelLoadErrorMessage}></SessionPanel>
    </>
  );
};

const SessionsTemplate = observer(SessionsTemplateFC);

export default SessionsTemplate;
