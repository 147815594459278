import React from 'react';
import ReactJson, { ThemeKeys } from 'react-json-view';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { observer } from 'mobx-react-lite';
import { Pivot, PivotItem } from '@fluentui/react';
import { IconButton } from '@fluentui/react';
import { SelectionMode } from '@fluentui/react/lib/Selection';
import { t } from 'i18next';

import customStyle from '@/components/SessionDetails/SessionDetailsStyles';
import config from '@/components/Sessions/Sessions.config.json';
import SessionStepChartTemplate from '@/components/SessionStepChart/SessionStepChartTemplate';
import { SplitPanelConfigType } from '@/components/SplitPanel/SplitPanelTypes';
import SplitPanel from '@/components/SplitPanel/SplitPanelWrapper';
import { TabMemoryKeys } from '@/constants/ExperimentConstants';
import { SystemIcons } from '@/constants/IconConstants';
import { ChartType, Namespaces as NS } from '@/constants/SystemConstants';
import { LogsView } from '@/constants/TranslationConstants';
import ColumnEditorPanelTemplate from '@/partials/ColumnEditorPanel/ColumnEditorPanelTemplate';
import { FilePreview } from '@/partials/FilePreview/FilePreview';
import { LoadingSpinner } from '@/partials/LoadingSpinner/LoadingSpinner';
import PageCommandBar from '@/partials/PageCommandBar/PageCommandBarTemplate';
import PageDivider from '@/partials/PageDivider/PageDivider';
import PageFilterBar from '@/partials/PageFilterBar/PageFilterBarTemplate';
import { RawLogsTreeView } from '@/partials/RawLogsTreeView/RawLogsTreeView';
import ScrollableContent from '@/partials/ScrollableContent/ScrollableContent';
import SessionInfoBlock from '@/partials/SessionInfoBlock/SessionInfoBlockTemplate';
import TableViewViewController from '@/partials/TableView/TableViewViewController';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { modifiedColumnConfiguration } from '@/utils/Helpers';

import { SessionDetailsVCType } from './SessionDetailsTypes';

import '@/styles/SplitPanel.css';
import styles from './SessionDetails.module.css';

const SessionDetailsTemplateFC: React.FC<SessionDetailsVCType> = (props: SessionDetailsVCType) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, sessionsStore, sessionDetailsStore } = rootStore;

  const { tabMemory } = appSettingsStore;
  const { isSessionSelected, isViewingDeepLink, labCompanyName, selectedSession, selectedSessionIsCancellable } = sessionsStore;

  const { isSessionLoading, panelMessageCount } = sessionDetailsStore;
  const {
    canViewInstanceResults,
    isCancelButtonDisabled,
    isDownloadButtonDisabled,
    isLogWindowItemSelected,
    isSessionStepColumnEditorOpen,
    isSessionStepWindowSelected,
    isSessionStepsSelected,
    logPath,
    selectLogWindowItem,
    selectSessionStepWindow,
    closeSessionStepColumnEditor,
    sessionStep,
    sessionStepEntireColumns,
    sessionStepGroupByValue,
    sessionStepColumnList,
    sessionStepColumnEditorKey,
    setSessionStepColumnsList,
  } = sessionDetailsStore;

  const {
    agentFilterItems,
    chart,
    checkboxVisibility,
    columnEditorUserSettings,
    content,
    farItems,
    filteredStep,
    generalCommandBarItems,
    groupByColumn,
    groupByColumns,
    compiledJsonStyle,
    rawJsonStyle,
    onLinkClick,
    onJsonLinkClick,
    selection,
    setContent,
    splitPanelConfig,
    stepDisplayItems,
    stepChanges,
    tableGroups,
  } = props;

  const activeTab: string = tabMemory[TabMemoryKeys.SESSION_DETAILS_MODAL];
  const activeJsonTab: string = tabMemory[TabMemoryKeys.SESSION_DETAILS_MODAL_JSON];
  const sessionId = selectedSession?.id;
  const isLoadingData = !selectedSession;
  const isLoadingSteps = !selectedSession?.steps;

  React.useEffect(() => {
    const generalContent = () => {
      return (
        <>
          {isLoadingData && <LoadingSpinner />}
          <PageCommandBar items={generalCommandBarItems}></PageCommandBar>
          <div className={styles['panel-tab']}>
            <div className={styles['columns']}>
              <div className={styles['column']}>
                <SessionInfoBlock session={selectedSession}></SessionInfoBlock>
              </div>
              <div className={styles['divider']}></div>
              <div className={styles['column']}>
                <SessionStepChartTemplate type={ChartType.DONUT} data={selectedSession}></SessionStepChartTemplate>
              </div>
            </div>
          </div>
        </>
      );
    };

    const stepsTabContent = () => {
      if (isLoadingSteps) {
        return (
          <div className={styles['panel-tab']}>
            <LoadingSpinner />
          </div>
        );
      }

      // NOTE: Eventually move these to the ViewController.
      const filteredSteps = filteredStep(stepDisplayItems);
      const selectedStepJson: object = isSessionSelected ? (sessionStep?.stepJSON as object) : {};
      const stepName = sessionStep?.name;

      const onStepJsonClick = () => {
        selectSessionStepWindow();
      };

      const onLogsHeaderClick = () => {
        selectLogWindowItem();
      };

      return (
        <>
          <PageFilterBar items={agentFilterItems} farItems={farItems}></PageFilterBar>
          <div className={styles['panel-tab']}>
            <SplitPanel index={0} config={splitPanelConfig} isAllowResize={isViewingDeepLink} split="horizontal">
              <TableViewViewController
                items={filteredSteps}
                columns={modifiedColumnConfiguration(sessionStepColumnList)}
                displayColumns={config.stepsDisplayFields}
                groups={tableGroups}
                groupByColumn={groupByColumn}
                groupByColumns={groupByColumns}
                enableToolBar={false}
                enableColumnSort={true}
                tableStyle={styles['session-steps-table']}
                selection={selection}
                selectionMode={SelectionMode.single}
                checkboxVisibility={checkboxVisibility}
                isCompact={true}
                isStickyHeader={true}
              />
              <div className={`${styles['pane']} ${styles['bottom-pane']}`}>
                <div className={styles['step-details-title']}>{t('step-details', { ns: NS.EXPERIMENTS })}</div>
                <div className={styles['step-details-container']}>
                  <SplitPanel index={1} config={splitPanelConfig} isAllowResize={isViewingDeepLink} split="vertical">
                    <div className={`${styles['pane']} ${styles['left-pane']}`}>
                      <div
                        className={`${styles['title']}${isSessionStepWindowSelected ? ` ${styles['hot']}` : ''}`}
                        onClick={() => onStepJsonClick()}
                      >
                        <IconButton
                          aria-label={t('steps-json', { ns: NS.EXPERIMENTS })}
                          iconProps={{ iconName: SystemIcons.STEPS_JSON }}
                        />
                        {t('steps-json', { ns: NS.EXPERIMENTS })}
                      </div>
                      <div className={styles['row-divider']}></div>
                      <div
                        className={`${styles['title']}${isLogWindowItemSelected ? ` ${styles['hot']}` : ''}`}
                        onClick={() => onLogsHeaderClick()}
                      >
                        <IconButton
                          aria-label={t(LogsView.LOGS, { ns: NS.LOGS_VIEW })}
                          iconProps={{ iconName: SystemIcons.LOGS }}
                        />
                        {t(LogsView.LOGS, { ns: NS.LOGS_VIEW })}
                      </div>
                      {isLogWindowItemSelected && sessionStep?.experimentId && (
                        <RawLogsTreeView
                          id={sessionStep?.experimentId}
                          companyName={labCompanyName}
                          path={logPath}
                          showLogText={false}
                          isTitleHighlighted={isLogWindowItemSelected}
                        />
                      )}
                    </div>
                    <div className={`${styles['pane']} ${styles['right-pane']}`}>
                      <div className={styles['logs-view-container']}>
                        {isSessionStepWindowSelected && (
                          <>
                            {!isSessionStepsSelected && <>Select Step to view JSON</>}
                            {isSessionSelected && isSessionStepsSelected && (
                              <>
                                <div className={`${styles['title']}`}>{stepName}</div>
                                <pre className={styles['json-block']}>{<ReactJson src={selectedStepJson} />}</pre>
                              </>
                            )}
                          </>
                        )}
                        {isLogWindowItemSelected && sessionStep && <FilePreview companyName={labCompanyName} />}
                      </div>
                    </div>
                  </SplitPanel>
                </div>
              </div>
            </SplitPanel>
            <ColumnEditorPanelTemplate
              isColumnEditorOpen={isSessionStepColumnEditorOpen}
              hideColumnEditor={closeSessionStepColumnEditor}
              columnsList={sessionStepColumnList}
              setColumnsList={setSessionStepColumnsList}
              entireColumns={sessionStepEntireColumns}
              columnEditorKey={sessionStepColumnEditorKey}
              userSettings={columnEditorUserSettings}
            ></ColumnEditorPanelTemplate>
          </div>
        </>
      );
    };

    const compiledJsonTabContent = () => {
      const json: object = isSessionSelected ? (selectedSession.json as object) : {};

      return (
        <>
          {isLoadingData && <LoadingSpinner />}
          {!isLoadingData && (
            <>
              <pre className={styles['json-block']}>
                {isSessionSelected && <ReactJson src={json} style={customStyle.jsonBackground} theme={compiledJsonStyle} />}
              </pre>
            </>
          )}
        </>
      );
    };

    const rawJsonTabContent = () => {
      return (
        <>
          {isLoadingData && <LoadingSpinner />}
          {!isLoadingData && (
            <>
              <pre className={styles['json-block']}>
                <SyntaxHighlighter language="json" style={rawJsonStyle} customStyle={customStyle.jsonBackground} showLineNumbers>
                  {isSessionSelected && selectedSession.rawJson}
                </SyntaxHighlighter>
              </pre>
            </>
          )}
        </>
      );
    };

    const generalTabItem = (
      <PivotItem headerText={t('general', { ns: NS.EXPERIMENTS })} key={'tab-general'} itemKey={'tab-general'}>
        <PageDivider />
        <ScrollableContent scrollable={true} scrollableX={true}>
          {isSessionSelected && generalContent()}
        </ScrollableContent>
      </PivotItem>
    );

    const stepsTabItem = (
      <PivotItem headerText={t('steps', { ns: NS.EXPERIMENTS })} key={'tab-steps'} itemKey={'tab-steps'}>
        <PageDivider />
        {isSessionSelected && stepsTabContent()}
      </PivotItem>
    );

    const compiledJsonTabItem = (
      <PivotItem headerText={t('compiled-json', { ns: NS.EXPERIMENTS })} key={'tab-compiled-json'} itemKey={'tab-compiled-json'}>
        <PageDivider />
        <ScrollableContent scrollable={true} scrollableX={true}>
          {isSessionSelected && compiledJsonTabContent()}
        </ScrollableContent>
      </PivotItem>
    );

    const rawJsonTabItem = (
      <PivotItem headerText={t('raw-json', { ns: NS.EXPERIMENTS })} key={'tab-raw-json'} itemKey={'tab-raw-json'}>
        <PageDivider />
        <ScrollableContent scrollable={true} scrollableX={true}>
          {isSessionSelected && selectedSession.rawJson && rawJsonTabContent()}
        </ScrollableContent>
      </PivotItem>
    );

    const jsonTabItem = (
      <PivotItem headerText={t('json', { ns: NS.EXPERIMENTS })} key={'tab-json'} itemKey={'tab-json'}>
        <PageDivider />
        <ScrollableContent scrollable={true} scrollableX={true}>
          <Pivot defaultSelectedKey={activeJsonTab} onLinkClick={onJsonLinkClick}>
            {isSessionSelected && compiledJsonTabItem}
            {isSessionSelected && selectedSession.rawJson && rawJsonTabItem}
          </Pivot>
        </ScrollableContent>
      </PivotItem>
    );

    const content = (
      <>
        <div className={styles['modal-body-content']}>
          <Pivot defaultSelectedKey={activeTab} onLinkClick={onLinkClick}>
            {generalTabItem}
            {stepsTabItem}
            {jsonTabItem}
          </Pivot>
        </div>
      </>
    );

    if (isSessionLoading) {
      setContent(<LoadingSpinner />);
    } else {
      setContent(content);
    }
  }, [
    canViewInstanceResults,
    chart,
    isCancelButtonDisabled,
    isDownloadButtonDisabled,
    isLogWindowItemSelected,
    isSessionLoading,
    isSessionSelected,
    isSessionStepColumnEditorOpen,
    isSessionStepWindowSelected,
    selectedSession,
    selectedSessionIsCancellable,
    sessionId,
    sessionStep,
    sessionStepColumnList,
    sessionStepEntireColumns,
    sessionStepGroupByValue,
    setContent,
    setSessionStepColumnsList,
    stepChanges,
    tabMemory,
    panelMessageCount,
    logPath,
  ]);

  return (
    <div className={styles['details-body']}>
      <>{content}</>
    </div>
  );
};

const SessionDetailsTemplate = observer(SessionDetailsTemplateFC);

export default SessionDetailsTemplate;
