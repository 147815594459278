import { NavLinkItemType } from '@/partials/NavLinkItem/NavLinkItemTypes';
import { RootStore } from '@/stores/RootStore';
import { ActionTypeBoolean } from '@/types/AppSettingsTypes';

class SideBarViewModel {
  protected _portal: string;
  protected _portalLinks: NavLinkItemType[];
  protected _isCollapsed: boolean;
  protected _toggleCollapsed: ActionTypeBoolean;

  constructor(rootStore: RootStore) {
    const { appSettingsStore, systemMessageStore } = rootStore;
    const { addGlobalMessage, addHelpMessage, clearNonPersistentGlobalMessages, globalMessages } = systemMessageStore;
    const { portal, isCollapsed, toggleCollapsed } = appSettingsStore;

    this._portal = portal;
    this._isCollapsed = isCollapsed;
    this._toggleCollapsed = toggleCollapsed;
  }

  public get portal(): string {
    return this._portal;
  }

  public get isCollapsed(): boolean {
    return this._isCollapsed;
  }

  public get toggleCollapsed(): ActionTypeBoolean {
    return this._toggleCollapsed;
  }

  public getLinkClass = (): string => {
    // NOTE: Placeholder function that will be removed once we clean up this store.
    return '';
  };

  public getSideBarClass = (): string => {
    // NOTE: Placeholder function that will be removed once we clean up this store.
    return '';
  };

  public getToggleIcon = (): string => {
    // NOTE: Placeholder function that will be removed once we clean up this store.
    return '';
  };
}

export default SideBarViewModel;
