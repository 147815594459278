import React from 'react';
import { observer } from 'mobx-react-lite';
import { PanelType } from '@fluentui/react';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { t } from 'i18next';

import { ExperimentInsights } from '@/components/_labs/ResultsExplorer/ExperimentInsights';
import { LogsSummary } from '@/components/_labs/ResultsExplorer/LogsSummary';
import LogsViewPanelTemplate from '@/components/LogsViewPanel/LogsViewPanelTemplate';
import { ResultExplorerIcons, SystemIcons } from '@/constants/IconConstants';
import { Navigation } from '@/constants/NavigationConstants';
import { PivotItems } from '@/constants/ResultExplorerConstants';
import { Delimiters, Namespaces as NS, TabMemoryKeys } from '@/constants/SystemConstants';
import { Common, Results } from '@/constants/TranslationConstants';
import { RawLogsTreeView } from '@/partials/RawLogsTreeView/RawLogsTreeView';
import SidePanelStyles from '@/partials/SidePanel/SidePanelStyles';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { openPageInNewTab, openTestUrlInNewTab } from '@/utils/Helpers';

import style from '@/components/LogsViewPanel/LogsViewPanel.module.css';

const LogsViewPanelViewControllerFC: React.FC = () => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, logsViewPanelStore, resultExplorerStore, systemMessageStore } = rootStore;
  const { tabMemory } = appSettingsStore;
  const { clearNonPersistentGlobalMessages } = systemMessageStore;
  const { closeLogsViewPanel, isLogsViewPanelOpen } = logsViewPanelStore;
  const { resultAnalyzerDisabled, selectedId, selectedName, selectedCompanyValue } = resultExplorerStore;

  const pageName = TabMemoryKeys.LOGS_VIEW;
  const resultExplorerPageName = TabMemoryKeys.RESULT_EXPLORER;
  const activeTab: string = tabMemory[pageName as string];
  const resultExplorerActiveTab = tabMemory[resultExplorerPageName as string];
  const resultsAnalyzer = t(Results.RESULT_ANALYZER, { ns: NS.RESULTS });
  const results = t(Common.RESULTS, { ns: NS.EXPERIMENTS });
  const components = [
    { headerText: t('logs', { ns: NS.TITLES }), component: RawLogsTreeView },
    { headerText: t('summary', { ns: NS.TITLES }), component: LogsSummary },
    { headerText: t('insights', { ns: NS.TITLES }), component: ExperimentInsights },
  ];
  const resultAnalyzerPath = `${Navigation.LABS.RESULT_TRIAGE}/id=${selectedId}&companyName=${selectedCompanyValue}`;

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages, selectedId]);

  const getHeaderText = (name: string, key: string) => {
    const headerKey =
      resultExplorerActiveTab === PivotItems.MYLAB_RESULTS
        ? t('experiment-definition-id', { ns: NS.TABLE })
        : t('experiment-instance-id', { ns: NS.TABLE });

    return (
      <>
        {name}
        <br />
        {headerKey}
        <span className={style['separator']}>{Delimiters.COLON}</span>
        {key}
      </>
    );
  };

  const panelHeaderText = getHeaderText(selectedName, selectedId);

  const userSettings = {
    type: PanelType.large,
    headerText: panelHeaderText,
    isOpen: isLogsViewPanelOpen,
    onDismiss: closeLogsViewPanel,
    styles: SidePanelStyles.resultExplorer,
  };

  const commandBarItems: ICommandBarItemProps[] = [
    {
      key: Results.RESULT_ANALYZER,
      text: resultsAnalyzer,
      title: resultsAnalyzer,
      iconProps: { iconName: ResultExplorerIcons.ANALYTICS },
      onClick: () => {
        openPageInNewTab(resultAnalyzerPath);
      },
      disabled: resultAnalyzerDisabled,
    },
    {
      key: Common.RESULTS,
      text: results,
      title: results,
      iconProps: { iconName: SystemIcons.OPEN_IN_NEW_WINDOW },
      onClick: () => {
        openTestUrlInNewTab(null, selectedId, null, null, selectedCompanyValue);
      },
    },
  ];

  return (
    <LogsViewPanelTemplate
      commandBarItems={commandBarItems}
      components={components}
      userSettings={userSettings}
      activeTab={activeTab}
    ></LogsViewPanelTemplate>
  );
};

const LogsViewPanelViewController = observer(LogsViewPanelViewControllerFC);

export default LogsViewPanelViewController;
