/*----------------------------------------------------------------------------------------------------------------------------
# SettingsPanelTemplate.tsx: 
#----------------------------------------------------------------------------------------------------------------------------*/

import React from 'react';
import { Namespace, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Icon, Link as FluentUILink } from '@fluentui/react';
import { t } from 'i18next';

import { SystemIcons } from '@/constants/IconConstants';
import { Navigation } from '@/constants/NavigationConstants';
import { Namespaces as NS } from '@/constants/SystemConstants';
import PortalSettings from '@/pages/PortalSettings/PortalSettingsTemplate';
import SidePanel from '@/partials/SidePanel/SidePanelTemplate';
import AppSettingsService from '@/services/AppSettingsService';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

import SidePanelStyles from '../SidePanel/SidePanelStyles';

import sidePanelStyles from '@/partials/SidePanel/SidePanel.module.css';

const SettingsPanelTemplateFC: React.FC = (): React.ReactElement => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const appSettings = new AppSettingsService(); // TODO: JOHN CHECK IF THIS IS CORRECT.
  const { appSettingsStore } = rootStore;
  const { isSettingsOpen, closeSettings } = appSettingsStore;

  // User Settings configuration.
  const FluentLink = (props) => <FluentUILink as={Link} {...props} />;
  const appVersion = appSettings.version;
  const lastUpdatedDate = appSettings.lastUpdatedDate;

  const onRenderFooterContent: () => JSX.Element = React.useCallback(() => {
    return (
      <div className="panel-footer">
        <p>
          <FluentLink to={Navigation.GANYMEDE.RELEASE_NOTES} title={t('click-for-history', { ns: NS.TITLES })}>
            <Icon className={sidePanelStyles['settings-footer-icon']} iconName={SystemIcons.INFO} />
            <Trans ns={NS.TITLES as Namespace}>portal-version</Trans>: {appVersion}
          </FluentLink>
        </p>
        <p>
          <Icon className={`${sidePanelStyles['settings-footer-icon']}`} iconName={SystemIcons.CALENDAR} />
          <Trans ns={NS.TITLES as Namespace}>last-updated</Trans>: {lastUpdatedDate}
        </p>
      </div>
    );
  }, [appVersion, lastUpdatedDate]);

  const userSettings = {
    headerText: t('portal-settings', { ns: NS.TITLES }),
    isOpen: isSettingsOpen,
    onRenderFooterContent: onRenderFooterContent,
    onDismiss: closeSettings,
    styles: SidePanelStyles.settings,
  };

  const content = <PortalSettings></PortalSettings>;

  return (
    <div>
      <SidePanel userSettings={userSettings} content={content}></SidePanel>
    </div>
  );
};

const SettingsPanelTemplate = observer(SettingsPanelTemplateFC);

export default SettingsPanelTemplate;
