import { RootStore } from '@/stores/RootStore';

class FooterViewModel {
  public isDebugMode: boolean;
  public isEditMode: boolean;
  public isAdvancedMode: boolean;
  public isDeveloperMode: boolean;
  public isOfflineMode: boolean;
  public isPageLoading: boolean;
  public isDarkMode: boolean;
  public isSettingsOpen: boolean;

  public debugModeMessage: string;
  public editModeMessage: string;
  public advancedModeMessage: string;
  public developerModeMessage: string;

  constructor(rootStore: RootStore) {
    const { appSettingsStore } = rootStore;
    const {
      isDebugMode,
      isEditMode,
      isAdvancedMode,
      isDeveloperMode,
      isOfflineMode,
      isPageLoading,
      isDarkMode,
      isSettingsOpen,
      debugModeMessage,
      editModeMessage,
      advancedModeMessage,
      developerModeMessage,
    } = appSettingsStore;

    this.isDebugMode = isDebugMode;
    this.isEditMode = isEditMode;
    this.isAdvancedMode = isAdvancedMode;
    this.isDeveloperMode = isDeveloperMode;
    this.isOfflineMode = isOfflineMode;
    this.isPageLoading = isPageLoading;
    this.isDarkMode = isDarkMode;
    this.isSettingsOpen = isSettingsOpen;

    this.debugModeMessage = debugModeMessage;
    this.editModeMessage = editModeMessage;
    this.advancedModeMessage = advancedModeMessage;
    this.developerModeMessage = developerModeMessage;
  }
}

export default FooterViewModel;
