import React from 'react';
import { observer } from 'mobx-react-lite';

import { SessionDetailsVMType } from '@/components/SessionDetails/SessionDetailsTypes';
import SessionDetailsViewController from '@/components/SessionDetails/SessionDetailsViewController';
import SessionDetailsViewModel from '@/components/SessionDetails/SessionDetailsViewModel';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

const SessionDetailsFC: React.FC<SessionDetailsVMType> = ({ title, sessionId }) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);

  const viewModel = React.useMemo(() => new SessionDetailsViewModel(rootStore, title, sessionId), [rootStore, title, sessionId]);

  return <SessionDetailsViewController viewModel={viewModel}></SessionDetailsViewController>;
};

const SessionDetails = observer(SessionDetailsFC);

export default SessionDetails;
