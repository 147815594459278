import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { CommandBarButton, Icon, TextField } from '@fluentui/react';
import { t } from 'i18next';

import { LoadingSpinner } from '@/components/_air/common';
import { LabDetailsTemplate } from '@/components/ManageLab/LabDetails/LabDetailsTemplate';
import { LabDetailsVMType } from '@/components/ManageLab/LabDetails/LabDetailsTypes';
import LabGeneralViewController from '@/components/ManageLab/LabGeneral/LabGeneralViewController';
import LabGeneralViewModel from '@/components/ManageLab/LabGeneral/LabGeneralViewModel';
import { Labels } from '@/constants/LabsConstants';
import { Navigation, NavigationIcon } from '@/constants/NavigationConstants';
import { IconNames, Namespaces as NS, TabMemoryKeys } from '@/constants/SystemConstants';
import { Labs } from '@/constants/TranslationConstants';
import PageContent from '@/partials/PageContent/PageContent';
import PageHeader from '@/partials/PageHeader/PageHeader';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

import styles from '@/components/ManageLab/LabDetails/LabDetails.module.css';

interface LabDetailsViewControllerProps {
  viewModel: LabDetailsVMType;
}

const LabDetailsViewControllerFC: React.FC<LabDetailsViewControllerProps> = ({ viewModel }) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, systemMessageStore, labDetailsStore } = rootStore;
  const { clearNonPersistentGlobalMessages } = systemMessageStore;
  const { tabMemory } = appSettingsStore;
  const { fetchLabs } = viewModel;
  const { selectedLab, setSelectedLab, labList, isLabBusy } = labDetailsStore;

  const labGeneralViewModel = useMemo(() => new LabGeneralViewModel(rootStore), [rootStore]);

  const [searchText, setSearchText] = useState('');

  const pageName = TabMemoryKeys.LAB_DETAILS;
  const activeTab: string = tabMemory[pageName as string];
  const title = t(Labs.LABS, { ns: NS.LABS });

  React.useEffect(() => {
    clearNonPersistentGlobalMessages();
  }, [clearNonPersistentGlobalMessages]);

  React.useEffect(() => {
    if (fetchLabs) {
      fetchLabs();
    }
  }, [fetchLabs]);

  if (isLabBusy) {
    return <LoadingSpinner />;
  }

  if (labList?.length > 0 && selectedLab) {
    const defaultLabName = (
      <div>
        <span className={styles['labs-dropdown']}>{selectedLab.LabName}</span>
      </div>
    );

    const filteredLabs = labList?.filter((lab) => lab.LabName?.toLowerCase().includes(searchText.toLowerCase()));

    const commandbar = (
      <CommandBarButton
        text={defaultLabName}
        menuProps={{
          items: [
            {
              key: 'searchBox',
              onRender: () => (
                <TextField
                  placeholder={Labels.searchLab}
                  value={searchText}
                  onChange={(e, newValue) => setSearchText(newValue || '')}
                  prefix={<Icon iconName={IconNames.SEARCH} />}
                />
              ),
            },
            ...filteredLabs.map((lab) => ({
              key: lab.LabId.toString(),
              text: lab.LabName,
              onClick: () => setSelectedLab(lab),
            })),
          ],
        }}
      />
    );

    const components = [
      { headerText: Labels.general, viewModel: labGeneralViewModel, component: LabGeneralViewController },
      // { headerText: Labels.systems, viewModel: labSystemsViewModel, component: LabSystemsViewController },
    ];

    return (
      <div className="fullscreen padding-top">
        <PageHeader icon={NavigationIcon[Navigation.GANYMEDE.LABS]} subTitle={commandbar}>
          {title}
        </PageHeader>
        <PageContent scrollable={false}>
          <div className="fullscreen">
            <LabDetailsTemplate components={components} activeTab={activeTab} />
          </div>
        </PageContent>
      </div>
    );
  }
};

const LabDetailsViewController = observer(LabDetailsViewControllerFC);

export default LabDetailsViewController;
