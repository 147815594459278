/*----------------------------------------------------------------------------------------------------------------------------
# App.tsx: 
# This page orchestrates the top-level functioning for the CRC Portal. 
# Application Routing is managed in the Routes.tsx page.
#----------------------------------------------------------------------------------------------------------------------------*/

import React from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { initializeIcons, MessageBarType as FluentMessageBarType, ThemeProvider } from '@fluentui/react';
import { FluentProvider, useThemeClassName } from '@fluentui/react-components';
import { t } from 'i18next';

import TelemetryViewController from '@/components/AppInsights/TelemetryViewController';
import { Namespaces as NS } from '@/constants/SystemConstants';
import MessagePageViewController from '@/pages/MessagePage/MessagePageViewController';
import { MessageBarMode } from '@/partials/MessageBar/MessageBarTypes';
import AppRoutes from '@/routes/AppRoutes';
import AppSettingsService from '@/services/AppSettingsService';
import { userService } from '@/services/request-services/UserServices';
import { RootStore, RootStoreContext } from '@/stores/RootStore';
import { SystemMessageType } from '@/types/SystemMessageTypes';

import { DEVELOPER_LINKS } from './routes/RouteLinks';
import { UserInfoType } from './types/UserTypes';
import { GanymedeApiScope } from './authConfig';

//----------------------------------------------------------------------------------------------------------------------------
// All Ganymede global stylesheet imports belong here.
//----------------------------------------------------------------------------------------------------------------------------
import './App.css'; // All generic global style declarations.
import '@/styles/Fluent.css'; // These are global FluentUI style declarations.
import '@/styles/FluentDetailsList.css'; // These are FluentUI DetailsList style declarations.
import '@/styles/Legacy.css'; // These are global legacy style declarations that may eventually be deleted.
import '@/styles/PowerBi.css'; // These are global PowerBI style declarations that may eventually be deleted.

initializeIcons(); // Required to work with FluentUI Icons.

// NOTE:
// https://github.com/microsoft/fluentui/issues/23626 v9 does not have applyTo='body' this is a work around until they fix.
// Which they seem to be doing here https://github.com/microsoft/fluentui/pull/29052.
// But this code is not in the latest package yet it seems.
const ApplyToBody = () => {
  const classes: string = useThemeClassName();

  React.useEffect(() => {
    // The truthy check makes sure we do not have an classList with empty/falsy/whitespace-only array values.
    // Otherwise, there are scenarios where we would get [' '] which would break the classList.add() method.
    const classList: string[] = classes.trim() ? classes.split(' ') : [];

    document.body.classList.add(...classList); // NOTE: If classList has empty elements, it will cause a fatal WSOD in the GUI.

    return () => document.body.classList.remove(...classList);
  }, [classes]);

  return null;
};

const AppFC: React.FC = () => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore, systemMessageStore, userInfoStore } = rootStore;
  const { addGlobalMessage } = systemMessageStore;
  const { tenantId, hasLabsPermissions, setUserInfo, setCompanyName, setInternalUser } = userInfoStore;
  const appSettings = new AppSettingsService();
  const {
    setPortal,
    setIsPartner,
    setPortalLinks,
    checkVersion,
    checkIsPartnerMode,
    isAdvancedMode,
    isDebugMode,
    isDeveloperMode,
    isPartnerMode,
    updateLabsLinks,
    updateNavigationLinks,
    v8theme,
    v9theme,
  } = appSettingsStore;

  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress, accounts } = useMsal();
  const { pathname } = useLocation();
  const [loading, setLoading] = React.useState(true);
  const [unloaded, setUnloaded] = React.useState(false);

  const portal = appSettings.getPortalRootFromPath(pathname);

  const getAccount = () => (accounts.length >= 1 ? accounts[0] : undefined);
  const getUserId = (): string => (accounts.length >= 1 ? accounts[0].localAccountId : '');

  window.onbeforeunload = () => {
    if (!isAuthenticated) {
      isAdvancedMode && console.log('[App] Redirected away during the login process');
      setUnloaded(true);
    }
  };

  const getUserProfileInformation = async (): Promise<void> => {
    try {
      const userInfo: UserInfoType = await userService.getMyProfile();
      const userIdMatches = getUserId() == userInfo.userId;
      const companyName = userInfo.companyName;
      const isInternalUser = userIdMatches && userInfo.userId !== null;

      isAdvancedMode && console.log('[App] getUserProfileInformation', userInfo);

      setCompanyName(companyName);
      setInternalUser(isInternalUser);
    } catch (error: any) {
      console.error('[App] Error! An error occurred fetching the Company Name.', error);

      if (isDeveloperMode || isDebugMode || isAdvancedMode) {
        const companyNameError: SystemMessageType = {
          message: 'company-name-error',
          id: 'company-name-error',
          error,
          namespace: NS.ERRORS,
          type: FluentMessageBarType.error,
          mode: MessageBarMode.rememberState,
        };
        addGlobalMessage(companyNameError);
      }
    }
  };

  const postLogin = async (loginMethod: string, response: any): Promise<void> => {
    isAdvancedMode && console.log(`[App] ${loginMethod} successful`, response);

    const userAccount = response.account;
    const userName = userAccount.username;

    instance.setActiveAccount(userAccount);
    setUserInfo(userAccount);
    isAdvancedMode && console.log('[App] postLogin - tenantId:', tenantId);
    setLoading(false);
    getUserProfileInformation();
    setIsPartner(checkIsPartnerMode(userName));
  };

  const login = async () => {
    if (inProgress === InteractionStatus.None && !isAuthenticated) {
      try {
        const loginResponse = await instance.ssoSilent(GanymedeApiScope);
        await postLogin('ssoSilent', loginResponse);
      } catch (error) {
        isAdvancedMode && console.warn(`[App] ssoSilent failed: ${error}`);

        try {
          const loginResponse = await instance.loginRedirect(GanymedeApiScope);
          await postLogin('loginRedirect', loginResponse);
        } catch (error) {
          console.error(`[App] loginRedirect failed: ${error}`);
        }
      }
    }

    isAdvancedMode && console.log('[App] logging in');

    if (isAuthenticated) {
      isAdvancedMode && console.log('[App] User authenticated');
      const userAccount = getAccount();
      const userName = userAccount.username;

      setUserInfo(userAccount);
      isAdvancedMode && console.log('[App] tenantId:', tenantId);
      setLoading(false);
      getUserProfileInformation();
      setIsPartner(checkIsPartnerMode(userName));
    }
  };

  React.useEffect(() => {
    login();
  });

  React.useEffect(() => {
    // Only set the Portal at the top application level.
    isAdvancedMode && console.log(`[App] Location has changed to: ${location.pathname}, portal=[${portal}]`);
    setPortal(portal);
  }, [location.pathname]);

  React.useEffect(() => {
    let links = [];

    links = updateLabsLinks(hasLabsPermissions, isPartnerMode);

    setPortalLinks(links);
  }, [hasLabsPermissions, isPartnerMode]);

  checkVersion();

  if (!isAuthenticated || loading || unloaded) {
    isAdvancedMode &&
      console.log(
        `[App] Render Loading: Authenticated: ${isAuthenticated}, loading: ${loading}, tenantId: ${tenantId}, hasLabsPermissions: ${hasLabsPermissions}`,
      );

    if (unloaded && !loading) {
      return <MessagePageViewController message={t('redirected', { ns: NS.ERRORS })} />;
    } else {
      return <MessagePageViewController message={t('loading', { ns: NS.DEFAULT })} />;
    }
  } else {
    isAdvancedMode &&
      console.log(
        `[App] Render Routes: Authenticated: ${isAuthenticated}, loading: ${loading}, tenantId: ${tenantId}, hasLabsPermissions: ${hasLabsPermissions}`,
      );

    return (
      <ThemeProvider applyTo="body" theme={v8theme} className="top-level-provider">
        <FluentProvider theme={v9theme} className="top-level-provider">
          <ApplyToBody />
          <TelemetryViewController>
            <AppRoutes />
          </TelemetryViewController>
        </FluentProvider>
      </ThemeProvider>
    );
  }
};

const App = observer(AppFC);

export default App;
