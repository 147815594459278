import React from 'react';
import { observer } from 'mobx-react-lite';
import { PartialTheme } from '@fluentui/react';
import { teamsDarkTheme, teamsLightTheme } from '@fluentui/react-components';

import SideBarViewModel from '@/partials/SideBar/SideBarViewModel';
import { RootStore, RootStoreContext } from '@/stores/RootStore';

import RegularPage from './RegularPageTemplate';
import { RegularPageVMType } from './RegularPageTypes';

import styles from './RegularPage.module.css'; // Apply any Layout level CSS Module styling here.

const RegularPageViewControllerFC: React.FC<RegularPageVMType> = (props: RegularPageVMType) => {
  const rootStore: RootStore = React.useContext(RootStoreContext);
  const { appSettingsStore } = rootStore;
  const { isDarkMode } = appSettingsStore;
  const sideBarViewModel = new SideBarViewModel(rootStore);

  const { children } = props;
  const styleCollapsed: string = sideBarViewModel.isCollapsed ? styles['collapsed'] : '';
  const theme: typeof PartialTheme = isDarkMode ? teamsDarkTheme : teamsLightTheme;

  return (
    <RegularPage styleCollapsed={styleCollapsed} theme={theme}>
      {children}
    </RegularPage>
  );
};

const RegularPageViewController = observer(RegularPageViewControllerFC);

export default RegularPageViewController;
