import React from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { NavLinkItemType } from '@/partials/NavLinkItem/NavLinkItemTypes';
import NavLinksTemplate from '@/partials/NavLinks/NavLinksTemplate';
import { NavLinksFCType } from '@/partials/NavLinks/NavLinksTypes';

const NavLinksFC: React.FC<NavLinksFCType> = (props: NavLinksFCType): React.ReactElement => {
  const { pathname } = useLocation();
  const { isCollapsed, children } = props;
  const level = 0;
  const rootLinks = children ? children.filter((navLink: NavLinkItemType) => (navLink.level ?? 0) === level) : [];

  const onToggle = () => {
    // Do nothing for now.
  };

  const onClick = () => {
    // We have a onClick event.
  };

  return (
    <NavLinksTemplate
      location={pathname}
      rootLinks={rootLinks}
      level={level}
      isCollapsed={isCollapsed}
      onToggle={onToggle}
      onClick={onClick}
    >
      {children}
    </NavLinksTemplate>
  );
};

const NavLinks = observer(NavLinksFC);

export default NavLinks;
